'use client';

import type { FC } from 'react';
import { useRef } from 'react';
import styles from './EmbeddedFrame.module.scss';
import { usePostMessage } from './usePostMessage';

type EmbeddedFrameProps = {
  frameSrc: string;
  onMessage?: (event: MessageEvent) => void;
  className: string;
};

export const EmbeddedFrame: FC<EmbeddedFrameProps> = ({
  frameSrc,
  onMessage = () => {},
  className
}) => {
  const frameRef = useRef<HTMLIFrameElement>(null);
  usePostMessage(frameRef, onMessage);

  return (
    <iframe
      data-testid="intake-form"
      className={`${styles.embedded_form} ${className}`}
      ref={frameRef}
      src={frameSrc}
    />
  );
};
