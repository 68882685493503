import * as React from 'react';
import type { SVGProps } from 'react';
const SvgApple = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 50 50"
		{...props}
	>
		<rect
			width={49}
			height={48.435}
			x={0.5}
			y={0.783}
			stroke="#8291AC"
			rx={24.218}
			style={{
				strokeOpacity: 1,
			}}
		/>
		<path
			fill="#000"
			d="M25.801 17.8c1.246-1.51 2.978-1.517 2.978-1.517s.257 1.42-.98 2.787c-1.322 1.46-2.824 1.22-2.824 1.22s-.282-1.147.826-2.49m-.667 3.486c.64 0 1.83-.81 3.378-.81 2.665 0 3.714 1.743 3.714 1.743s-2.05.965-2.05 3.304c0 2.639 2.553 3.548 2.553 3.548s-1.785 4.622-4.197 4.622c-1.108 0-1.969-.687-3.136-.687-1.19 0-2.37.712-3.138.712-2.203 0-4.985-4.384-4.985-7.908 0-3.467 2.355-5.286 4.564-5.286 1.436 0 2.55.762 3.297.762"
			style={{
				fill: '#000',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgApple;
