import { LoaderBlue } from '@bw/elements/icons';
import styles from './LoaderCss.module.scss';

export function LoaderCss() {
	return (
		<div className={styles['loader_css']}>
			{/* for yellow bg use this class 'page__loader theme__yellow' */}
			<div className="page__loader"> 
				<LoaderBlue className='svg' width={100} height={100} />
			</div>
		</div>
	);
}

export default LoaderCss;
