import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSpotify = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 50 50"
		{...props}
	>
		<rect
			width={49}
			height={49}
			x={0.5}
			y={0.5}
			stroke="#8291AC"
			rx={24.5}
			style={{
				stroke: '#8291ac',
				strokeOpacity: 1,
			}}
		/>
		<path
			fill="#1ED760"
			d="M30.3 24c-2.9-1.7-7.7-1.9-10.5-1-.4.1-.9-.1-1-.6-.1-.4.1-.9.6-1.1 3.2-1 8.5-.8 11.8 1.2.4.2.5.8.3 1.2-.3.4-.8.5-1.2.3m-.1 2.5c-.2.3-.6.4-1 .2-2.4-1.5-6.1-1.9-9-1-.4.1-.8-.1-.9-.5s.1-.8.5-.9c3.3-1 7.3-.5 10.1 1.2.4.3.5.7.3 1M29.1 29c-.2.3-.5.3-.8.2-2.1-1.3-4.8-1.6-7.9-.9-.3.1-.6-.1-.7-.4s.1-.6.4-.7c3.4-.8 6.4-.4 8.7 1 .4.2.5.5.3.8M25 16c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9"
			style={{
				fill: '#1ed760',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgSpotify;
