'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import Link from 'next/link';
import { GET_FOOTER_ITEMS } from '../../queries';
import styles from './footer-slim.module.scss';

interface FooterData {
	footerCollection: {
		items: {
			isFranchiseFooter: boolean;
			socialIconsCollection: {
				items: {
					name: string;
					url: string;
					icon: {
						height: number;
						title: string;
						url: string;
						width: number;
					};
				}[];
			};
			copyrightText: string;
			addressHeading: string;
			address: string;
			addressCityStateZip: string;
		}[];
	};
}

export const Footer = (props) => {
	const { data } = useSuspenseQuery<FooterData>(GET_FOOTER_ITEMS, {
		variables: {
			component: props.name as string,
		},
	});
	const path = data.footerCollection.items[0];

	return (
		<footer className={styles.footerContainer}>
			<div className={styles.copyright}>
				&copy;2024 Brightway Insurance
			</div>
			<div className={styles.links}>
				<div>
					<Link href="/terms-of-service" target="_blank">
						Terms
					</Link>
				</div>
				<div>{'  |  '}</div>
				<div>
					<Link href="/privacy" target="_blank">
						Privacy Policy
					</Link>
				</div>
				<div>{'  |  '}</div>
				<div>
					<Link href="/do-not-sell" target="_blank">
						Do Not Sell My Personal Information
					</Link>
				</div>
			</div>
			<div className={styles.address}>
				{path.address} {path.addressCityStateZip}
			</div>
		</footer>
	);
};

export default Footer;
