import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

export enum LoggerEvents {
    GeneralErrorWebApp = 'GeneralErrorWebApp:',
    NotFoundErrorWebApp = 'NotFoundErrorWebApp:',
    UncaughtExceptionWebApp = 'UncaughtExceptionWebApp',
    InfoEventWebApp = 'InfoEventWebApp:'
}

const defaultBrowserHistory = {
    url: '/',
    location: { pathName: '' },
    listen: () => []
}
let browserHistory = defaultBrowserHistory
if (typeof window !== 'undefined') {
    browserHistory = { ...browserHistory, ...window.history }
    browserHistory.location.pathName = browserHistory.url
}

const reactPlugin = new ReactPlugin()

// We'll assume to only have one key
let instance: ApplicationInsights;

const appInsights = (appInsightsKey?: string) => {
    if (instance) return instance;
    if (!appInsightsKey) return;

    instance = new ApplicationInsights({
        config: {
            instrumentationKey: appInsightsKey,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: browserHistory }
            }
        }
    })
    if (typeof window !== 'undefined') {
        instance.loadAppInsights()
    }
    return instance;
}

// When we get application insights to work for static and SSR pages
// we can replace this logger with it 
const logger = console

export { appInsights, logger, reactPlugin }

