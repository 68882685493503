import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCheck = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 12 10"
		{...props}
	>
		<path
			fill="#fff"
			d="M3.818 7.66.955 4.794 0 5.75l3.818 3.818L12 1.386l-.954-.954z"
		/>
	</svg>
);
export default SvgCheck;
