import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDeepBluePin = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<g clipPath="url(#deep_blue_pin_svg__a)">
			<path
				fill="#003049"
				d="M10 19.773 4.697 14.47a7.5 7.5 0 1 1 10.606 0zm4.125-6.481a5.832 5.832 0 1 0-8.25 0L10 17.417zM10 10.833A1.667 1.667 0 1 1 10 7.5a1.667 1.667 0 0 1 0 3.333"
			/>
		</g>
		<defs>
			<clipPath id="deep_blue_pin_svg__a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default SvgDeepBluePin;
