import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSendPlane = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<g clipPath="url(#send-plane_svg__a)">
			<path
				fill="#003049"
				d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.68.045L12 14l6-8-8 6z"
				style={{
					fill: '#003049',
					fillOpacity: 1,
				}}
			/>
		</g>
		<defs>
			<clipPath id="send-plane_svg__a">
				<path
					fill="#fff"
					d="M0 0h24v24H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
				/>
			</clipPath>
		</defs>
	</svg>
);
export default SvgSendPlane;
