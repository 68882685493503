import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWhiteArrow = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 25"
		{...props}
	>
		<path
			fill="#fff"
			d="m13 16.672 5.364-5.364 1.414 1.414L12 20.5l-7.778-7.778 1.414-1.414L11 16.672V4.5h2z"
		/>
	</svg>
);
export default SvgWhiteArrow;
