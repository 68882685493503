import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlayBtn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 20 21"
		{...props}
	>
		<path
			stroke="#003049"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={1.667}
			d="M4.168 4.658c0-.81 0-1.214.169-1.437a.83.83 0 0 1 .615-.329c.279-.017.616.208 1.289.657l8.763 5.842c.556.37.835.556.931.79a.83.83 0 0 1 0 .638c-.097.234-.375.42-.931.79L6.24 17.452c-.673.45-1.01.674-1.29.657a.83.83 0 0 1-.614-.329c-.169-.223-.169-.628-.169-1.437z"
			style={{
				stroke: '#003049',
				strokeOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgPlayBtn;
