import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsBusiness = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M80.03 34.97H63.91v-8.7c0-2.36-1.92-4.27-4.27-4.27H40.66c-2.36 0-4.27 1.92-4.27 4.27v8.7H20.25c-.69 0-1.25.56-1.25 1.25v40.49c0 .69.56 1.25 1.25 1.25h59.78c.69 0 1.25-.56 1.25-1.25V36.22c0-.69-.56-1.25-1.25-1.25m-41.16-8.7c0-.98.79-1.77 1.77-1.77h18.98c.98 0 1.77.79 1.77 1.77v8.7H58.2v-6.49c0-.69-.56-1.25-1.25-1.25H43.31c-.69 0-1.25.56-1.25 1.25v6.49h-3.19zm16.83 8.7H44.56v-5.24H55.7zm-34.2 2.5h57.28v7.41l-8.52 4.26v-2.19c0-.69-.56-1.25-1.25-1.25h-6.36c-.69 0-1.25.56-1.25 1.25v6.62L50.14 59.2l-12.36-6.18v-6.07c0-.69-.56-1.25-1.25-1.25h-6.36c-.69 0-1.25.56-1.25 1.25v1.64l-7.42-3.71zm46.26 12.92v5.77H63.9V48.2h3.86zm-32.48 1.38v4.4h-3.86v-7.96h3.86v3.57zm43.5 23.69H21.5V47.68l7.42 3.71v6.03c0 .69.56 1.25 1.25 1.25h6.36c.69 0 1.25-.56 1.25-1.25v-1.6l11.8 5.9c.18.09.37.13.56.13s.38-.04.56-.13l10.71-5.35v1.05c0 .69.56 1.25 1.25 1.25h6.36c.69 0 1.25-.56 1.25-1.25v-5.48l8.52-4.26v27.79z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsBusiness;
