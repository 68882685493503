import { map_pin, map_pin_active } from "./stock-images";

export const ITEMS_PER_PAGE = {
	BLOGS: 5,
	COMPANIES_LIST: 100,
};

export const TEMP_CONSTANTS = {
	DOMAIN_URL:
		'https://bw-web-apps-brightway-com.purpletree-c5e8258c.eastus.azurecontainerapps.io/',
};

export const GEO_CORDINATES = {
	LATITUDE: 37.09024,
	LONGITUDE: -95.712891,
};
export const MAP_PIN_URL = {
	NORMAL: map_pin,
	ACTIVE: map_pin_active
};

export const FORM_ERROR = {
	REQUIRED: 'This field is required',
	FNAME: 'Enter valid first name',
	LNAME: 'Enter valid last name',
	EMAIL: 'Enter a valid email',
	PHONENUMBER: 'Enter valid phone number',
	NAME: 'Enter a valid name',
};
