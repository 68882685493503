import { Image } from '@bw/elements';
import Link from 'next/link';
import { CalendlyButton } from '../../CalendlyButton/CalendlyButton';
import { ActionLink, MenuItem } from '../types';
import styles from './header-desktop-menu.module.scss';

interface DesktopMenuProps {
  menu: MenuItem[]
  activeMenuItem: MenuItem | null;
  onMenuItemClick: () => void;
  menuHeight: number | null;
  onClose: () => void;
  actionLinks: ActionLink[];
}

export const DesktopMenu = ({ activeMenuItem, onMenuItemClick, menuHeight, onClose, actionLinks }: DesktopMenuProps) =>
  activeMenuItem && (
    <div
      className={styles.megaMenuContainer}
      style={{ maxHeight: `${menuHeight}px` }}
    >
      <div className={`${styles.menuDesktopBanner} ${activeMenuItem.containsDualList ? styles.containsDualList : ''}`}>
        <div className={styles.menuDesktopContentWrapper}>
          <h5 className={styles.menuHeading}>
            {activeMenuItem.title}
          </h5>
        </div>
        <div>
          <div className={styles.menuDesktopContentWrapper}>
            <div>
              <h5 className={styles.subMenuHeadingSection}>Explore</h5>
              <ul data-lenis-prevent>
                {activeMenuItem.subMenuCollection?.items.map((subMenuItem, index) => (
                  <li
                    key={index}
                    className={styles.menuDesktopContent}
                    data-menu-index={index}
                  >
                    {subMenuItem.__typename === 'MenuItem' ? (
                      <h3>{subMenuItem.title}</h3>
                    ) : (
                      <h3>
                        {!subMenuItem.containsBookACallFunctionality ? (
                          <Link
                            href={subMenuItem.url}
                            onClick={onMenuItemClick}
                          >
                            {subMenuItem.text}
                          </Link>
                        ) : (
                          <CalendlyButton
                            className={styles['menu-list-desktop']}
                            title={subMenuItem.text}
                            isMenuButton
                          />
                        )}
                      </h3>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className={styles.menuBottomContent}>
              {activeMenuItem.hideMenuActionSection ? (
                <></>
              ) : (
                actionLinks.map((item, index) => (
                  <h5 key={index}>
                    <Link
                      href={item.url}
                      onClick={onMenuItemClick}
                    >
                      {item.text}
                    </Link>
                  </h5>
                ))
              )}
            </div>
          </div>
        </div>
          <div
            className={styles.menuDesktopImageContent}
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            {activeMenuItem.menuBannerImage?.url ? (
              <Image
                src={activeMenuItem.menuBannerImage.url}
                alt={activeMenuItem.menuBannerImage.title}
                width={1000}
                height={1000}
              />
            ) : (
              <></>
            )}
          </div>
      </div>
      <div className={styles.megaMenuFooter}>
        <button
          onClick={onClose}
          onKeyDown={(e) => {
            // Close the menu on pressing the ESC key
            if (e.key === 'Escape') {
              onClose();
            }
          }}
          title="Close Button"
        >
          Close &nbsp;
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M18 6L6 18M6 6L18 18"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );