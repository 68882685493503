import { ButtonLoader } from '@bw/elements/icons';
import React, { useMemo } from 'react';
import { FormMetaState } from '../formMetaState';
import styles from './formButtonGrow.module.scss';

interface FormButtonGrowProps {
	id?: string;
	title: string;
	className?: string;
	buttonClassName?: string;
	cardClassName?: string;
	type?: 'button' | 'submit' | 'reset';
	metaState: FormMetaState;
	onClick?: (item: any) => void;
}

const FormButtonGrow: React.FC<FormButtonGrowProps> = ({
	title,
  className,
	buttonClassName = '',
	cardClassName = '',
	metaState,
  ...props
}) => {
  const isDisabled = useMemo(() => {
    return [FormMetaState.success, FormMetaState.submitting, FormMetaState.fail, FormMetaState.offline].includes(metaState);
  }, [metaState])

  const formStateClassName = useMemo(() => {
    return (metaState === FormMetaState.success) ? styles.success 
    : [FormMetaState.fail, FormMetaState.offline].includes(metaState) ? styles.fail 
    : '';
  }, [metaState])

  const buttonTitle = useMemo(() => {
    return (metaState === FormMetaState.submitting) ? (
      <>
        <ButtonLoader className={styles.loader} height={24} width={24}/>
        Wrapping things up...
      </>
    ) 
    : title;
  }, [metaState])

  const onClick = (e) => {
    const elem = document.getElementsByClassName(styles.formButtonGrowElement)[0];

    console.log(elem.getBoundingClientRect());

    if (props.onClick) {
      props.onClick(e);
    }
  }
  
	return (
    <div className={`${styles.formButtonGrow} ${formStateClassName} ${className ? className: '' }`}>
      {(metaState === FormMetaState.success) ? (
        <div className={styles.formButtonGrowContent} />
      ) : (
        <button
          onClick={onClick}
          className={styles.formButtonGrowElement}
          disabled={isDisabled}
          {...props}
        >
          {buttonTitle}
        </button>
      )}
    </div>
  );
};

export default FormButtonGrow;
