import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 25 25"
		{...props}
	>
		<path
			stroke="#8291AC"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="m6.5 9.5 6 6 6-6"
			style={{
				stroke: '#8291ac',
				strokeOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgChevronDown;
