import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPointer = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 60 60"
		{...props}
	>
		<path
			fill="#F0FF00"
			stroke="#003049"
			strokeWidth={1.5}
			d="M17.867 12.904c6.54-6.541 17.145-6.541 23.686 0a16.75 16.75 0 0 1 2.616 20.294L29.71 57.934 15.25 33.198a16.75 16.75 0 0 1 2.617-20.294Z"
			style={{
				fill: '#f0ff00',
				fillOpacity: 1,
				stroke: '#003049',
				strokeOpacity: 1,
			}}
		/>
		<g clipPath="url(#pointer_svg__a)">
			<g clipPath="url(#pointer_svg__b)">
				<path
					fill="#003049"
					d="M36.727 25.197a2.85 2.85 0 0 0 1.127-.974c.357-.521.538-1.12.538-1.778 0-1.223-.423-2.16-1.257-2.784-.825-.618-2.009-.931-3.517-.931h-5.684a.28.28 0 0 0-.193.076.24.24 0 0 0-.08.181v1.544l-4.81-2.072a.38.38 0 0 0-.286-.02.35.35 0 0 0-.207.185l-.78 1.75a.32.32 0 0 0 .003.265c.04.084.115.15.208.18l8.248 2.667a.29.29 0 0 0 .247-.033.25.25 0 0 0 .114-.21v-2.085H33.5c1.37 0 2.035.496 2.035 1.518 0 1.105-.206 2.021-2.733 2.021-1.839 0-10.38-.713-12.074-.855a.38.38 0 0 0-.274.086.33.33 0 0 0-.118.251v2.515c0 .095.043.186.118.25a.38.38 0 0 0 .275.088c1.069-.091 10.49-.893 12.085-.893 1.887 0 2.26.174 2.778.633.394.35.586.84.586 1.499 0 1.17-.804 1.74-2.46 1.74H30.4v-2.515a.25.25 0 0 0-.11-.207.28.28 0 0 0-.244-.038l-8.132 2.39a.35.35 0 0 0-.218.183.31.31 0 0 0 .002.273l.91 1.873c.041.084.118.15.212.178a.38.38 0 0 0 .277-.023l4.567-2.084v2.141q0 .105.081.182.082.076.193.077h5.762c1.706 0 3.041-.348 3.971-1.034.939-.692 1.415-1.698 1.415-2.988 0-.835-.243-1.558-.721-2.15-.411-.508-.96-.868-1.635-1.074z"
					style={{
						fill: '#003049',
						fillOpacity: 1,
					}}
				/>
			</g>
			<g fill="#003049" clipPath="url(#pointer_svg__c)">
				<path
					d="M37.835 19.435v-.395h-.145v-.082h.398v.082h-.145v.395zM38.155 19.435v-.477h.129l.146.287.145-.288h.128v.478h-.108v-.305l-.123.238h-.086l-.124-.238v.305z"
					style={{
						fill: '#003049',
						fillOpacity: 1,
					}}
				/>
			</g>
		</g>
		<defs>
			<clipPath id="pointer_svg__a">
				<path
					fill="#fff"
					d="M0 0h19v14H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
					transform="translate(20.21 18.42)"
				/>
			</clipPath>
			<clipPath id="pointer_svg__b">
				<path
					fill="#fff"
					d="M0 0h18.747v14.022H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
					transform="translate(20.336 18.42)"
				/>
			</clipPath>
			<clipPath id="pointer_svg__c">
				<path
					fill="#fff"
					d="M0 0h1.013v.477H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
					transform="translate(37.69 18.957)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default SvgPointer;
