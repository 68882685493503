import type { SVGProps } from 'react';
const SvgLogoVector = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 141 286"
		{...props}
	>
		<path
			fill="#F0FF00"
			fillRule="evenodd"
			d="M141 42.917 49.173.82a6.92 6.92 0 0 0-5.572-.424 6.93 6.93 0 0 0-4.064 3.853L24.293 40.67a7 7 0 0 0 .055 5.512 6.93 6.93 0 0 0 4.064 3.723L141 88.666zm0 81.228c-55.507-4.391-114.994-9.682-133.35-11.327-1.912-.166-3.917.479-5.351 1.806A7.08 7.08 0 0 0 0 119.841v52.331c0 1.972.828 3.871 2.299 5.217a7.08 7.08 0 0 0 5.37 1.824c11.717-1.064 74.716-6.771 133.331-11.582zm0 74.9v43.888l-87.027 42.252c-1.545.866-3.567 1.069-5.406.479-1.84-.59-3.347-1.954-4.156-3.705l-17.783-38.968a6.91 6.91 0 0 1-.037-5.695 6.9 6.9 0 0 1 4.248-3.798z"
			clipRule="evenodd"
			style={{
				fill: '#f0ff00',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgLogoVector;
