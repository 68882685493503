import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUploadFile = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 33 41"
		{...props}
	>
		<path
			fill="#4F5E78"
			d="M14.5 34.5h4v-8.35l3.2 3.2 2.8-2.85-8-8-8 8 2.85 2.8 3.15-3.15zm-10 6q-1.65 0-2.825-1.175T.5 36.5v-32q0-1.65 1.175-2.825T4.5.5h16l12 12v24q0 1.65-1.175 2.825T28.5 40.5zm14-26v-10h-14v32h24v-22z"
		/>
	</svg>
);
export default SvgUploadFile;
