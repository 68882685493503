'use client';
import Cookies from 'js-cookie';
import Script from 'next/script';
import Button, { ButtonProps } from '../utility/button/button';
import './calendlyButton.scss';

const CALENDLY_URL = 'https://calendly.com/brightway-franchise/welcome-call';

const getUTM = () => {
  // return the first cookie that is found
  const firstCookie = (keys: string[]) => keys.map(key => Cookies.get(key)).find(value => value);

  return { 
    utmSource: firstCookie(['leadSourceId', 'utm_source', 'SourceId']) ?? 'Calendly',
    utmContent: firstCookie(['utm_content', 'REFERER']),
    utmCampaign: firstCookie(['utm_campaign', 'CampaignId']),
  };  
};

export type CalendlyButtonProps = 
  Omit<ButtonProps, 'title' | 'backgroundColor' | 'borderColor' | 'textColor'> & 
  { title?: string };

export function CalendlyButton({
  title = 'Book a Call',
  ...buttonProps
}: CalendlyButtonProps) {
  const params = getUTM();
  const onClick = () => {
    (window as any).Calendly.initPopupWidget({
      url: `${CALENDLY_URL}`,
      utm: params,
    });
  };

  return (
    <>
      <Script src={'https://assets.calendly.com/assets/external/widget.js'} />
      <Button
        onClick={onClick}
        title={title || 'Book a Call'}
        paletteVariant='primary'
        {...buttonProps}
      />
    </>
  );
}
