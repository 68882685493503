export const severities = ['debug', 'info', 'warn', 'error'] as const;

export type Severity = (typeof severities)[number];

interface LogEntry {
	severity: Severity;
	tags?: string[];
	message: string[];
}

type LogMessageHandler = (logEntry: LogEntry) => unknown;

const createLogSystem = () => {
	const target = new EventTarget();
	const listeners = new Map<LogMessageHandler, EventListener>();

	return {
		onMessage(handler: LogMessageHandler) {
			const listener = (e: Event) => handler((e as CustomEvent).detail);
			listeners.set(handler, listener);
			target.addEventListener('message', listener);
		},

		remove(handler: LogMessageHandler) {
			const listener = listeners.get(handler);
			if (listener) {
				target.removeEventListener('message', listener);
			}
		},

		emit(logEntry: LogEntry) {
			target.dispatchEvent(
				new CustomEvent<LogEntry>('message', {
					detail: logEntry,
				})
			);
		},
	};
};

export const logSystem = createLogSystem();

/** Convenience function for log listeners to filter out messages based on message severity */
export const severityMatches = (severity: Severity, verbosity: Severity) =>
	severities.indexOf(severity) >= severities.indexOf(verbosity);
