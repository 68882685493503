'use client';
import { useRouter } from 'next/navigation';
import { gtmEvent } from '../GTM/gtmEvent';
import { EmbeddedFrame } from '../embedded-frame/EmbeddedFrame';
import { useAppContext } from '../form-stepper/StepperProvider';
import styles from './IntakeForm.module.scss';
import { useFrameSrc } from './getFrameSrc';

// TODO: This components probably should be in app since it uses router
// when/if we reorganize the repo we should look at moving it
interface IntakeFormProps {
	intakeFormUrl: string;
	onClose: () => void;
	storeNumber?: string;
}

export const IntakeForm = ({ intakeFormUrl, onClose, storeNumber }: IntakeFormProps) => {
	const { setShowFormStepper } = useAppContext();
	const { push } = useRouter();

	const frameSrc = useFrameSrc(intakeFormUrl, storeNumber);

	const onMessage = (event: MessageEvent) => {
		if (event.data.type === 'close-form') {
			setShowFormStepper(false);
			onClose();
		}

		if (event.data.type === 'thank-you-reroute') {
			const redirectURL = event.data.body;
			setShowFormStepper(false);
			push(redirectURL);
		}

		if (event.data.type === 'gtm-event') {
			const intakeEvent = event.data.body;
			gtmEvent(`embedded_${intakeEvent.eventName}`, intakeEvent.params);
		}
	};

	return frameSrc &&
		<EmbeddedFrame
			frameSrc={frameSrc}
			onMessage={onMessage}
			className={styles['intake-form']}
		/>;
};
