import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPause = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 51 51"
		{...props}
	>
		<circle
			cx={25.5}
			cy={25.5}
			r={25.5}
			fill="#fff"
			style={{
				fill: '#fff',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#000"
			d="M20.575 14A2.575 2.575 0 0 0 18 16.575v18.53a2.575 2.575 0 1 0 5.15 0v-18.53A2.575 2.575 0 0 0 20.576 14m9.272 0a2.575 2.575 0 0 0-2.576 2.575v18.53a2.575 2.575 0 1 0 5.15 0v-18.53A2.575 2.575 0 0 0 29.848 14"
			style={{
				fill: '#000',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgPause;
