// This is currently only referenced by HalfTextImageHalfFullForm
'use client'
import { LoggerEvents, logger } from '@bw/core';
import { LoaderBlue } from '@bw/elements/icons';
import { FormResponse } from '@bw/external-apis';
import { useState } from 'react';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { SubmitHandler, useForm } from 'react-hook-form';
import { FORM_ERROR } from '../../constants';
import { ButtonProps } from '../../types/type';
import { gtmEvent } from '../GTM/gtmEvent';
import FormButton from '../utility/formButton/formButton';
import styles from './Form.module.scss';
import { readHiddenInputs } from './form-helpers';

export type ContactUsForm = {
  visitorFirstName: string;
  visitorLastName: string;
  visitorPhone: string;
  visitorEmail: string;
  emailSubject: string;
  emailMessage: string;
  captchaToken: string;
};

export type BecomeAnAgentForm = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  previousInsuranceExperience: string;
  cashAvailableForInvestment: string;
  captchaToken: string;
  ga_id: string;
  gclid: string;
};

export type CustomerServiceForm = {
  ContactUs: string;
  Email: string;
  FirstName: string;
  InsuranceType: string;
  LastName: string;
  Message: string;
  Phone: string;
  InsuranceCompany?: string;
  PolicyNumber?: string;
  captchaToken: string;
};

export type ContactForm = ContactUsForm | BecomeAnAgentForm | CustomerServiceForm;

export type RequestState =
	| { status: 'ready' | 'loading' | 'success' }
	| {
		status: 'error';
		error: Error;
	};

interface FormProps {
	formId: string;
	formData: {
		name: string;
		id: string;
		labelText: string;
		inputType: string;
		placeholder?: string | null;
		selectOptions?: string[] | null;
		textInForm?: string | null;
		isRequired: boolean;
		validationRegex: string | undefined;
		validationErrorMessage: string;
	}[];
	buttonData?: ButtonProps;
	formAction?: (formId: string, data: ContactForm) => Promise<FormResponse>;
}

export function Form(props: FormProps) {
	const formAction = props.formAction;

	if (!formAction) {
		throw new Error('Form action is required');
	}

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<ContactForm>();
	const [requestState, setRequestState] = useState<RequestState>({
		status: 'ready',
	});
	const [hideForm, setHideForm] = useState<boolean>(false);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getCaptchaToken = async (action: string | undefined): Promise<string> => {
		const message = 'executeRecaptcha not available on form submit, possible react script loading error.';
		if (!executeRecaptcha) {
			logger.error(LoggerEvents.GeneralErrorWebApp, { message });
			return 'no recaptcha';
		}
		try {
			const token = await executeRecaptcha(action);
			return token;
		} catch (error) {
			logger.error(LoggerEvents.GeneralErrorWebApp, { message });
		}
		return 'no recaptcha';
	}

	const onSubmit: SubmitHandler<ContactForm> = async (data, event) => {
		if (!event?.target) { return }

		// hidden inputs don't trigger the onChange event, so we need to manually read them
		data = { ...data, ...readHiddenInputs(event.target as HTMLFormElement) };

		setRequestState({ status: 'loading' });
		setHideForm(true);

		try {
			const token = await getCaptchaToken(props.formId);
			data.captchaToken = token;

			const response = await formAction(props.formId, data);

			if (typeof response.error === 'string') {
				throw new Error(response.error);
			}

			setRequestState({ status: 'success' });
			gtmEvent('form_success', { formName: props.formId ?? 'unknown-form' });
			reset();
		} catch (error) {
			console.log('caught error');
			if (error instanceof Error) {
				setRequestState({ status: 'error', error });
				setHideForm(false);
			}
		}
	};

	const ErrorDisplay = ({ state }: { state: RequestState }) => {
		return (
			<>
				{state.status === 'error' && (
					<div className={styles.error}>{state.error.message}</div>
				)}
			</>
		);
	};
	const StateDisplay = ({ state }: { state: RequestState }) => {
		return (
			<>
				{state.status === 'loading' && (
					<div className="is__pageloader">
						<figure>
							<LoaderBlue width={100} height={100} />
						</figure>
					</div>
				)}
			</>
		);
	};
	const ThanksDisplay = ({ state }: { state: RequestState }) => {
		return (
			<>
				{state.status === 'success' && (
					<div className={styles.success}>
						<h5 className="headingTagline">Submission Successful!</h5>
						<h2>Thank you!</h2>
						<p>Check your email. We’ll be in touch soon!</p>
					</div>
				)}
			</>
		);
	};
	const clearErrorState = () => {
		if (requestState.status === 'error') {
			setRequestState({ status: 'ready' });
		}
	};

	return (
		<>
			<div className={styles['formContainer']}>
				{!hideForm ? (
					<form
						id={props.formId}
						onSubmit={handleSubmit(onSubmit)}
						onChange={clearErrorState}
					>
						<fieldset>
							<input type="hidden" name="gclid" />
							<input type="hidden" name="ga_id" />

							{props.formData.map((field, index) => {
								const {
									name,
									id,
									labelText,
									inputType,
									placeholder,
									selectOptions,
									textInForm,
									isRequired,
									validationErrorMessage,
								} = field;
								const validationRegex = field.validationRegex;

								const renderField = () => {
									const inputDataTestId = labelText
										.replace(/\s/g, '-')
										.replace(/[^a-zA-Z-]/g, '')
										.toLowerCase();
									const pattern = validationRegex
										? {
											value: RegExp(validationRegex),
											message: validationErrorMessage,
										}
										: undefined;
									switch (inputType) {
										case 'text':
											return (
												<>
													<input
														id={id}
														type="text"
														data-testid={inputDataTestId}
														{...register(name as any, {
															required: isRequired
																? FORM_ERROR.REQUIRED
																: false,
															pattern,
														})}
														placeholder={placeholder ?? ''}
													/>
													<span data-testid={`${inputDataTestId}-error`}>
														{(errors[name] ? errors[name].message : '')}
													</span>
												</>
											);
										case 'email':
											return (
												<>
													<input
														type="text"
														id={id}
														data-testid={inputDataTestId}
														{...register(name as any, {
															required: isRequired
																? FORM_ERROR.REQUIRED
																: false,
															pattern,
														})}
														placeholder={placeholder ?? ''}
													/>
													<span data-testid={`${inputDataTestId}-error`}>
														{errors[name] ? errors[name].message : ''}
													</span>
												</>
											);
										case 'number':
											return (
												<>
													<input
														id={id}
														type="number"
														data-testid={inputDataTestId}
														{...register(name as any, {
															required: isRequired
																? FORM_ERROR.REQUIRED
																: false,
															pattern,
														})}
														placeholder={placeholder ?? ''}
													/>

													<span data-testid={`${inputDataTestId}-error`}>
														{errors[name] ? errors[name].message : ''}
													</span>
												</>
											);
										case 'select':
											return (
												<>
													<select
														id={id}
														data-testid={inputDataTestId}
														{...register(name as any, {
															required: isRequired
																? FORM_ERROR.REQUIRED
																: false,
															pattern,
														})}
													>
														<option value="">{placeholder ?? 'Select'}</option>
														{selectOptions?.map((option, optionIndex) => (
															<option
																value={String(option).trim()}
																key={optionIndex}
															>
																{option}
															</option>
														))}
													</select>
													<span data-testid={`${inputDataTestId}-error`}>
														{errors[name] ? errors[name].message : ''}
													</span>
												</>
											);
										case 'textarea':
											return (
												<>
													<textarea
														id={id}
														data-testid={inputDataTestId}
														{...register(name as any, {
															required: isRequired
																? FORM_ERROR.REQUIRED
																: false,
															pattern,
														})}
													></textarea>
													<span data-testid={`${inputDataTestId}-error`}>
														{errors[name] ? errors[name].message : ''}
													</span>
												</>
											);
										default:
											return null;
									}
								};

								return (
									<div
										key={index}
										className={styles['formField']}
										data-aos="fade-up"
									>
										{textInForm && <p>{textInForm}</p>}
										<label htmlFor={id}>{labelText}</label>
										{renderField()}
									</div>
								);
							})}

							{props.buttonData && (
								<FormButton
									id={`${props.formId}-submit`}
									title={props.buttonData.title}
									isDisabled={requestState.status === 'loading'}
									type="submit"
									data-aos="fade-up"
								/>
							)}
							<ErrorDisplay state={requestState} />
						</fieldset>
					</form>
				) : (
					<StateDisplay state={requestState} />
				)}
			</div>
			<ThanksDisplay state={requestState} />
		</>
	);
}

export default Form;
