import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOutbox = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 28 27"
		{...props}
	>
		<path
			fill="#003049"
			d="M24 18.5v5H4v-5H.667v5c0 1.833 1.5 3.333 3.333 3.333h20c1.834 0 3.334-1.5 3.334-3.333v-5zM5.667 8.5l2.35 2.35 4.317-4.3v13.617h3.333V6.55l4.317 4.3 2.35-2.35L14 .167z"
		/>
	</svg>
);
export default SvgOutbox;
