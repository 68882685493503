import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCoverIcon4 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#F0FF00"
			d="m59.74 37.03 6.49-12.13s0-.03.01-.05c.04-.08.06-.17.08-.25.02-.07.04-.14.05-.22 0-.08-.01-.16-.02-.24s-.01-.16-.04-.23c-.02-.07-.07-.13-.1-.2a1 1 0 0 0-.14-.22c-.01-.01-.02-.03-.03-.04l-7.73-8.06c-.24-.25-.56-.39-.9-.39H41.56c-.34 0-.67.14-.9.39l-7.71 8.06s-.02.03-.03.05c-.05.05-.09.1-.13.16-.03.05-.05.11-.07.16-.01.03-.03.06-.04.08-.05.13-.07.27-.07.41v.09c0 .12.03.24.08.35.01.03.02.06.03.08 0 .01 0 .03.02.04l6.19 12.3c-8.34 3.97-14.12 12.47-14.12 22.3 0 13.61 11.07 24.69 24.68 24.69s24.68-11.07 24.68-24.69c0-9.77-5.63-18.44-14.43-22.45zM49.49 81.66c-12.23 0-22.18-9.95-22.18-22.19 0-8.86 5.22-16.51 12.74-20.07l2.26 4.5c.04.09.1.16.16.23-5.81 2.67-9.87 8.54-9.87 15.34 0 9.31 7.58 16.89 16.89 16.89s16.89-7.58 16.89-16.89c0-6.98-4.26-12.99-10.32-15.56l2.5-4.68c8 3.58 13.11 11.4 13.11 20.24 0 12.23-9.95 22.19-22.18 22.19M35.1 59.47c0-7.93 6.46-14.39 14.39-14.39s14.39 6.46 14.39 14.39-6.46 14.39-14.39 14.39S35.1 67.4 35.1 59.47m.78-33.92h16.58a1.25 1.25 0 0 0 0-2.5H36.78l5.32-5.56h14.78l6.72 7.02-6.89 12.88-2.86 5.35c-.07.12-.1.26-.12.39-1.36-.35-2.77-.56-4.24-.56-1.68 0-3.29.25-4.82.71 0-.17-.04-.35-.13-.51l-2.17-4.31c2.24-.76 4.63-1.18 7.12-1.18.61 0 1.09-.44 1.2-1.02.01-.08.05-.15.05-.23 0-.69-.56-1.25-1.25-1.25-2.89 0-5.67.5-8.25 1.42l-5.36-10.66zM68.56 20.45c.19 0 .38-.04.57-.14l5.09-2.59a1.25 1.25 0 1 0-1.13-2.23L68 18.08a1.25 1.25 0 0 0-.55 1.68c.22.43.66.68 1.11.68zM77.17 23.06h-5.6a1.25 1.25 0 0 0 0 2.5h5.6a1.25 1.25 0 0 0 0-2.5M25.5 17.72l5.09 2.59a1.24 1.24 0 0 0 1.68-.54c.31-.61.07-1.37-.55-1.68l-5.08-2.6a1.25 1.25 0 0 0-1.68.55c-.31.62-.07 1.37.55 1.68zM30.1 24.31c0-.69-.56-1.25-1.25-1.25h-5.6a1.25 1.25 0 0 0 0 2.5h5.6c.69 0 1.25-.56 1.25-1.25"
			style={{
				fill: '#f0ff00',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgCoverIcon4;
