import * as React from 'react';
import type { SVGProps } from 'react';
const SvgButtonLoader = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<rect width={2} height={6} x={11} fill="#8291AC" rx={1} />
		<rect width={2} height={6} x={11} y={18} fill="#8291AC" rx={1} />
		<rect
			width={2}
			height={6}
			y={13}
			fill="#003049"
			rx={1}
			transform="rotate(-90 0 13)"
		/>
		<rect
			width={2}
			height={6}
			x={18}
			y={13}
			fill="#8291AC"
			rx={1}
			transform="rotate(-90 18 13)"
		/>
		<rect
			width={2}
			height={6}
			x={2.808}
			y={4.222}
			fill="#8291AC"
			rx={1}
			transform="rotate(-45 2.808 4.222)"
		/>
		<rect
			width={2}
			height={6}
			x={15.535}
			y={16.95}
			fill="#8291AC"
			rx={1}
			transform="rotate(-45 15.535 16.95)"
		/>
		<rect
			width={2}
			height={6}
			x={4.222}
			y={21.192}
			fill="#8291AC"
			rx={1}
			transform="rotate(-135 4.222 21.192)"
		/>
		<rect
			width={2}
			height={6}
			x={16.95}
			y={8.465}
			fill="#8291AC"
			rx={1}
			transform="rotate(-135 16.95 8.465)"
		/>
	</svg>
);
export default SvgButtonLoader;
