import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsPet = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M61.5 49.57c-2.28-3.96-6.38-6.32-10.94-6.32-4.57 0-8.66 2.36-10.94 6.32L28.31 69.16c-1.88 3.25-1.88 7.14 0 10.4s5.25 5.2 9 5.2h26.5c3.76 0 7.12-1.94 9-5.2 1.88-3.25 1.88-7.14 0-10.4zm9.15 28.74c-1.43 2.47-3.98 3.95-6.84 3.95h-26.5c-2.85 0-5.41-1.48-6.84-3.95s-1.43-5.42 0-7.9l11.31-19.59c1.83-3.17 5.11-5.07 8.78-5.07 3.66 0 6.95 1.89 8.78 5.07l11.31 19.59c1.43 2.47 1.43 5.42 0 7.9M32.61 49.71v-6.84c0-4.58-3.73-8.31-8.31-8.31S16 38.29 16 42.87v6.84c0 4.58 3.73 8.31 8.31 8.31s8.31-3.73 8.31-8.31zm-2.5 0c0 3.2-2.6 5.81-5.81 5.81s-5.81-2.6-5.81-5.81v-6.84c0-3.2 2.6-5.81 5.81-5.81s5.81 2.6 5.81 5.81zM39.7 39.46c4.58 0 8.31-3.73 8.31-8.31v-6.84c0-4.58-3.73-8.31-8.31-8.31s-8.31 3.73-8.31 8.31v6.84c0 4.58 3.73 8.31 8.31 8.31m-5.81-15.15c0-3.2 2.6-5.81 5.81-5.81s5.81 2.6 5.81 5.81v6.84c0 3.2-2.6 5.81-5.81 5.81s-5.81-2.6-5.81-5.81zM76.56 34.56c-4.58 0-8.31 3.73-8.31 8.31v6.84c0 4.58 3.73 8.31 8.31 8.31s8.31-3.73 8.31-8.31v-6.84c0-4.58-3.73-8.31-8.31-8.31m5.81 15.15c0 3.2-2.6 5.81-5.81 5.81s-5.81-2.6-5.81-5.81v-6.84c0-3.2 2.6-5.81 5.81-5.81s5.81 2.6 5.81 5.81zM61.17 39.46c4.58 0 8.31-3.73 8.31-8.31v-6.84c0-4.58-3.73-8.31-8.31-8.31s-8.31 3.73-8.31 8.31v6.84c0 4.58 3.73 8.31 8.31 8.31m-5.81-15.15c0-3.2 2.6-5.81 5.81-5.81s5.81 2.6 5.81 5.81v6.84c0 3.2-2.6 5.81-5.81 5.81s-5.81-2.6-5.81-5.81z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsPet;
