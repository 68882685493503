"use client";
import { Check } from "@bw/elements/icons";
import { ChangeEvent, FormEventHandler, InputHTMLAttributes } from "react";
import { FieldError, UseFormReturn, ValidationValueMessage } from "react-hook-form";
import { FORM_ERROR } from '../../../constants';
import { ConditionalFieldError } from "../errors";
import styles from './formInputs.module.scss';

interface FormTextInputProps {
  context: UseFormReturn<any>,
  id: string,
  type?: string;
  inputMode?: string;
  label?: string,
  required?: boolean,
  className?: string,
  onInput?: FormEventHandler<HTMLInputElement>,
  formError?: string,
  placeholder?: string,
  children?: any;
  patternSetting?: ValidationValueMessage<RegExp>;
  minLength?: number;
  maxLength?: number;
}

export const FormTextInput = ({
  context: { register, formState: { errors } },
  id,
  type = 'text',
  inputMode = 'text',
  label = '',
  required = false,
  className = styles.textInput,
  onInput = undefined,
  formError = FORM_ERROR.REQUIRED,
  placeholder = '',
  children,
  ...inputProps
} : FormTextInputProps & InputHTMLAttributes<HTMLInputElement>,
) => {

  return (
    <div className={className}>
      {children}
      {label && (
        <label htmlFor={id}>
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        inputMode={inputMode}
        onInput={onInput}
        required={required}
        placeholder={placeholder}
        {...register(id, {
          required: required ? formError : undefined,
        })}
        {...inputProps}
      />
      <ConditionalFieldError error={errors?.[id] as FieldError} />
    </div>
  )
}

const handleFormZipCodeOnInput = (e: ChangeEvent<HTMLInputElement>) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, '')
}

export const FormZipCodeInput = ({
  context: { register, formState: { errors } },
  id = 'postalCode',
  type = 'text',
  inputMode = 'numeric',
  label = 'Zip Code',
  placeholder = '',
  required = false,
  className = styles.textInput,
  patternSetting = {
    value: /^(?!00000)\d{5}$/,
    message: 'Enter valid Postal Code',
  },
  minLength = 5,
  maxLength = 5,
  children,
  ...inputProps
} : FormTextInputProps & InputHTMLAttributes<HTMLInputElement>,
) => {
  {children}
  return (<div className={className}>
    {label ?? (
      <label htmlFor={id}>
        {label}
      </label>
    )}
    <input
      id={id}
      type={type}
      inputMode={inputMode}
      minLength={minLength}
      maxLength={maxLength}
      required={required}
      placeholder={placeholder}
      onInput={handleFormZipCodeOnInput}
      {...register(id, {
        required: required ? FORM_ERROR.REQUIRED : undefined,
        pattern: patternSetting,
      })}
      {...inputProps}
    />
    <ConditionalFieldError error={errors?.[id] as FieldError} />
  </div>)
}

export const FormCheckboxInput = ({
  context: { register, formState: { errors }, getValues },
  id,
  type = 'checkbox',
  inputMode = 'none',
  label,
  required = false,
  className = styles.checkboxInput,
  onInput = undefined,
  ...inputProps
}: FormTextInputProps & InputHTMLAttributes<HTMLInputElement>,
) => {
  const isChecked: boolean = getValues(id);

  return (
    <div className={className}>
      <input
        id={id}
        type={type}
        inputMode={inputMode}
        onInput={onInput}
        required={required}
        {...register(id)}
        {...inputProps}
      />
      <label htmlFor={id}>
        <span className={isChecked ? styles.checkmark : styles.checkempty} >
          {isChecked && <span className={styles.icon}><Check height={18} width={18} /></span>}
        </span>
        {label}
      </label>
      <ConditionalFieldError error={errors?.[id] as FieldError} />
    </div>
  )
}
