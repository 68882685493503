'use client';
import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import { useDebouncedCallback } from 'use-debounce';

export function PageButton({
	type,
	hide,
}: {
	type: 'NEXT' | 'PREVIOUS';
	hide: boolean;
}) {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { replace } = useRouter();

	const buttonText = type === 'NEXT' ? 'Next Page' : 'Previous Page';
	const updateFunction = (pageString: string | null): string => {
		if (!pageString) {
			return '1';
		}
		const pageNum = Number(pageString);

		if (type === 'NEXT') {
			return (pageNum + 1).toString();
		}
		if (pageNum <= 1) {
			return '0';
		}
		return (pageNum - 1).toString();
	};

	const onClick = useDebouncedCallback(() => {
		const params = new URLSearchParams(searchParams);
		const currentPage = params.get('page') ?? '0';
		const newPage = updateFunction(currentPage);
		params.set('page', newPage);
		replace(`${pathname}?${params.toString()}`);
	}, 50);

	const styles = { padding: '1em', width: '150px' };

	if (hide) {
		return (
			<button disabled={true} style={styles}>
				{buttonText}
			</button>
		);
	}

	return (
		<>
			<button style={styles} onClick={onClick}>
				{buttonText}
			</button>
		</>
	);
}
