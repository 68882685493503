import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsToys = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M82.17 68.92c-5.2 0-7.74-1.22-10.43-2.51-2.82-1.35-5.74-2.76-11.51-2.76s-8.69 1.4-11.51 2.76c-2.69 1.29-5.23 2.51-10.43 2.51s-7.74-1.22-10.43-2.51c-2.82-1.35-5.74-2.76-11.51-2.76a1.25 1.25 0 0 0 0 2.5c5.2 0 7.74 1.22 10.43 2.51 2.82 1.35 5.74 2.76 11.51 2.76s8.69-1.4 11.51-2.76c2.69-1.29 5.23-2.51 10.43-2.51s7.74 1.22 10.43 2.51c2.82 1.35 5.74 2.76 11.51 2.76a1.25 1.25 0 0 0 0-2.5M82.17 57.07c-4.14 0-6.59-.77-8.78-1.74 1.83-1.32 4.54-3.69 6.79-7.33 3.65-5.9 4.14-10.67 4.16-10.87a1.253 1.253 0 0 0-1.25-1.37H69.5l-4.29-9.05c-.21-.44-.65-.71-1.13-.71H40.11a1.257 1.257 0 0 0-1.2 1.62l2.53 8.14H16.25a1.257 1.257 0 0 0-1.23 1.5l2.9 14.59c-.5-.02-1.02-.04-1.57-.04a1.25 1.25 0 0 0 0 2.5c5.2 0 7.74 1.22 10.43 2.51 2.82 1.35 5.74 2.76 11.51 2.76s8.69-1.4 11.51-2.76c2.69-1.29 5.23-2.51 10.43-2.51s7.74 1.22 10.43 2.51c2.82 1.35 5.74 2.76 11.51 2.76a1.25 1.25 0 0 0 0-2.5zM66.75 35.76h-9.72l-2.51-7.26h8.78l3.46 7.26zM41.81 28.5h10.06l2.51 7.26H44.06L41.8 28.5zm.42 9.76 1.32 4.25H18.62l-.85-4.25h24.46m6.5 16.3c-2.69 1.29-5.23 2.51-10.43 2.51s-7.74-1.22-10.43-2.51c-2.05-.98-4.15-1.99-7.34-2.46l-1.41-7.08h26.13a1.257 1.257 0 0 0 1.2-1.62l-1.6-5.13h36.77c-.39 1.7-1.33 4.83-3.56 8.43-2.62 4.24-6 6.6-7.3 7.4-2.55-1.19-5.42-2.29-10.52-2.29-5.77 0-8.69 1.4-11.51 2.76zM82.17 82.01c-5.2 0-7.74-1.22-10.43-2.51-2.82-1.35-5.74-2.76-11.51-2.76s-8.69 1.4-11.51 2.76c-2.69 1.29-5.23 2.51-10.43 2.51s-7.74-1.22-10.43-2.51c-2.82-1.35-5.74-2.76-11.51-2.76a1.25 1.25 0 0 0 0 2.5c5.2 0 7.74 1.22 10.43 2.51 2.82 1.35 5.74 2.76 11.51 2.76s8.69-1.4 11.51-2.76c2.69-1.29 5.23-2.51 10.43-2.51s7.74 1.22 10.43 2.51c2.82 1.35 5.74 2.76 11.51 2.76a1.25 1.25 0 0 0 0-2.5"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsToys;
