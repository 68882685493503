import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#003049"
			d="m12 6.19 5 4.5v7.81h-2v-6H9v6H7v-7.81zm0-2.69-10 9h3v8h6v-6h2v6h6v-8h3z"
		/>
	</svg>
);
export default SvgHome;
