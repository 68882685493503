'use client';
import { Image } from '@bw/elements';
import { getCookie } from 'cookies-next';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import AnnouncementBar from '../announcement-bar/AnnouncementBar';
import { useAppContext } from '../form-stepper/StepperProvider';
import { HeaderCtaSection } from './header-cta-section';
import { DesktopMenu } from './header-menu/HeaderDesktopMenu';
import { MobileMenu } from './header-menu/HeaderMobileMenu';
import styles from './header.module.scss';
import { Header, MenuItem, PageCategory, SubMenuItem } from './types';

export function SiteHeader({ headerData, pageCategories }: { headerData: Header, pageCategories: PageCategory[] }) {
	const [menuOpen, setMenuOpen] = useState(false);
	const headerWrapperRef = useRef<HTMLDivElement>(null);
	const headerRef = useRef<HTMLDivElement>(null);
	const [menuHeight, setMenuHeight] = useState<number>(0); // TODO(khaney): use responsive layout
	const [activeMenuItem, setActiveMenuItem] = useState<MenuItem | null>(null);
	const [isMobile, setIsMobile] = useState(true); // TODO: use media query instead
	const [heightChange, setHeightChange] = useState(0);
	const { showFormStepper } = useAppContext();
	const { announcementBannerHeight } = useAppContext();
	const [activeBack, setActiveBack] = useState(false); // do we need this?
	const [activeSubmenu, setActiveSubmenu] = useState<SubMenuItem[] | null | undefined>(null);

	useEffect(() => {
		setActiveBack(false);
		// Check if window is defined to avoid server-side rendering errors
		if (typeof window !== 'undefined') {
			setIsMobile(window.innerWidth < 1150);

			const handleResize = () => {
				setIsMobile(window.innerWidth < 1150);
			};
			const handleHeightChange = () => {
				setHeightChange(window.innerHeight);
			};

			window.addEventListener('resize', handleResize);
			window.addEventListener('resize', handleHeightChange);

			return () => {
				window.removeEventListener('resize', handleResize);
				window.removeEventListener('resize', handleHeightChange);
			};
		}
	}, []);

	useEffect(() => {
		const announcementClosedCookie = getCookie('announcementClosed');
		if (!announcementClosedCookie && headerWrapperRef.current && !isMobile) {
			const headerWrapperHeight = headerWrapperRef.current.clientHeight;

			setMenuHeight(
				window.innerHeight -
				((headerWrapperHeight || 80) + announcementBannerHeight)
			);
		} else if (headerWrapperRef.current) {
			const headerWrapperHeight = headerWrapperRef.current.clientHeight;
			setMenuHeight(window.innerHeight - (headerWrapperHeight || 80));
		}
	}, [menuOpen, heightChange]);

	const handleFocusWithinModal = (e) => {
		const modal = headerRef.current;
		if (!modal) {
			return;
		}
		const focusableElements = modal.querySelectorAll(
			'a, button, [tabindex]:not([tabindex="-1"])'
		);
		const focusableElementsArray =
			Array.prototype.slice.call(focusableElements);
		const firstFocusableElement = focusableElementsArray[0];
		const lastFocusableElement =
			focusableElementsArray[focusableElementsArray.length - 1];
		if (e.key === 'Tab' || e.keyCode === 9) {
			if (e.shiftKey) {
				if (document.activeElement === firstFocusableElement) {
					e.preventDefault();
					lastFocusableElement.focus();
				}
			} else {
				if (document.activeElement === lastFocusableElement) {
					e.preventDefault();
					firstFocusableElement.focus();
				}
			}
		}
	};

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape' && !showFormStepper) {
				setMenuOpen(false);
				setActiveMenuItem(null);
				document.body.classList.remove('disableScroll');
				document.body.setAttribute('data-lenis-prevent', 'false');
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [menuOpen]);

	useEffect(() => {
		const handleKeyDown = (event) => {
			handleFocusWithinModal(event);
		};

		if (menuOpen && headerRef?.current) {
			headerRef?.current?.addEventListener('keydown', handleKeyDown);

			return () => {
				headerRef?.current?.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [menuOpen]);

	const toggleMenu = () => {
		setActiveBack(false);
		setMenuOpen(!menuOpen);
		setActiveMenuItem(null);
		if (menuOpen) {
			document.body.classList.remove('disableScroll');
			document.body.setAttribute('data-lenis-prevent', 'false');
		} else {
			document.body.classList.add('disableScroll');
			document.body.setAttribute('data-lenis-prevent', 'true');
		}
	};

	const handleMenuItemClick = (menuItem: MenuItem) => {
		if (!menuOpen) {
			setMenuOpen(true);
			setActiveMenuItem(menuItem);
			setActiveSubmenu(menuItem?.subMenuCollection?.items);
			document.body.classList.add('disableScroll');
			document.body.setAttribute('data-lenis-prevent', 'true');
		} else if (menuOpen && activeMenuItem === menuItem) {
			setMenuOpen(false);
			setActiveMenuItem(null);
			document.body.classList.remove('disableScroll');
			document.body.setAttribute('data-lenis-prevent', 'false');
		} else {
			setActiveMenuItem(menuItem);
		}
	};
	const handleMenuClose = () => {
		setActiveBack(false);
		if (menuOpen) {
			setMenuOpen(false);
			setActiveMenuItem(null);
			document.body.classList.remove('disableScroll');
			document.body.setAttribute('data-lenis-prevent', 'false');
		}

		document.body.classList.remove('disableScroll');
		document.body.setAttribute('data-lenis-prevent', 'false');
	};
	const clearActiveSubmenuOnTransition = (): void => {
		if (!menuOpen && !isMobile) {
			setActiveSubmenu(null);
		}
	};

	const menu = headerData?.menuItemsCollection?.items;
	const img = headerData?.logo?.image?.url || '';
	const ctaButtons = headerData?.ctaLinksCollection?.items;
	const actionLinks = headerData?.actionLinksCollection?.items;
	const barClassName = `${styles['bar']} ${menuOpen ? styles['bar--clicked'] : ''}`;

	const onBackClick = () => {
		if (menuOpen) {
			setActiveBack(true);
		}
		setActiveMenuItem(null);
		setTimeout(() => setActiveBack(false), 500);
	};

	const onSubmenuClick = (menuItem: MenuItem) => {
		setActiveBack(false);
		setActiveMenuItem(menuItem);		
	}

	return (
		<div className={styles.headerContainer}>
			<AnnouncementBar
				pageCategories={pageCategories}
				customClass={menuOpen && isMobile ? 'announcementBar--hide' : ''}
			/>
			<header className={styles.header} ref={headerRef}>
				<div className="container container--max">
					<div className={styles.headerWrapper} ref={headerWrapperRef}>
						<Link
							href={headerData?.logo?.imageUrl}
							title={headerData?.logo?.title}
							className={styles.headerLogo}
							onClick={handleMenuClose}
						>
							<Image
								src={img}
								alt={headerData?.logo?.altText}
								width={170}
								height={24}
								priority={true}
							/>
						</Link>
						<nav aria-label="Header menu" className={styles.logoMenuWrapper}>
							<ul className={styles.navList}>
								{menu.map((menuItem, index) => (
									<li
										key={index}
										className={`${styles.navItem} ${activeMenuItem === menuItem ? styles['navItem--activeMenu'] : ''}`}
									>
										{menuItem.__typename === 'MenuItem' ? (
											<div
												tabIndex={0}
												className={styles.navContent}
												onClick={() => {
													handleMenuItemClick(menuItem);
												}}
												onKeyDown={(e) => {
													// Handle Enter key press
													if (e.key === 'Enter') {
														handleMenuItemClick(menuItem);
													}
												}}
												aria-haspopup="true"
												role="button"
											>
												<span>{menuItem.title}</span>
												<ChevronDown />
											</div>
										) : (
											<span aria-haspopup="false">
												<Link
													href={menuItem.url}
													onClick={handleMenuClose}
												>
													{menuItem.text}
												</Link>
											</span>
										)}
									</li>
								))}
							</ul>
						</nav>

						<button
							title="toggle menu"
							className={styles.hamburgerButton}
							type="button"
							onClick={toggleMenu}
						>
							{menuOpen ? (
								<span className={styles.hamburgerText}> Close</span>
							) : (
								<></>
							)}
							<span className={styles.hamburgerIcon}>
								<span className={barClassName}></span>
								<span className={barClassName}></span>
								<span className={barClassName}></span>
							</span>
						</button>

						<HeaderCtaSection
							ctaButtons={ctaButtons}
							className={styles.desktopCta}
							containsStartAQuoteButton={headerData.containsStartAQuoteButton}
							containsBookACallButton={headerData.containsBookACallButton}
						/>
					</div>

					<div data-testid="mega-menu"
						className={`${styles.megaMenu} ${menuOpen ? styles['megaMenu--opened'] : ''}`}
						onTransitionEnd={clearActiveSubmenuOnTransition}
					>
						{isMobile ? (
							<MobileMenu 
								menuContent={menu} 
								menuHeight={menuHeight} 
								activeMenuItem={activeMenuItem} 
								onItemSelected={onSubmenuClick}
								onBackClick={onBackClick} 
								activeBack={activeBack} 
								onCloseClick={handleMenuClose} 
								actionLinks={actionLinks}
								ctaSection={
									<HeaderCtaSection
										ctaButtons={ctaButtons}
										heightVariant='narrow'
										className={styles.mobileButtonWrapper}
										containsStartAQuoteButton={headerData.containsStartAQuoteButton}
										containsBookACallButton={headerData.containsBookACallButton} />
								} 
							/>
						) : (
							activeSubmenu &&
								<DesktopMenu
									onMenuItemClick={handleMenuClose}
									menuHeight={menuHeight}
									activeMenuItem={activeMenuItem}
									onClose={handleMenuClose}
									actionLinks={actionLinks} menu={[]}
								/>
						)}
					</div>
				</div>
			</header>
		</div>
	);
}

const ChevronDown = () =>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="17"
		height="16"
		viewBox="0 0 17 16"
		fill="none"
	>
		<path
			height="20"
			d="M4.75977 6L8.75977 10L12.7598 6"
			stroke="white"
			strokeWidth="1.33333"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>


export default SiteHeader;
