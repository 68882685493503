'use client'

import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from "react"
import { useAppContext } from "../form-stepper/StepperProvider"

export function NavigationEvents() {
    const pathname = usePathname()
    const searchParams = useSearchParams()
    const URL = `${pathname}${searchParams}`
    const { showFormStepper, setShowFormStepper } = useAppContext()
    const [currentURL, setCurrentURL] = useState<string>()

    if (!currentURL) {
        setCurrentURL(URL)
    }

    useEffect(() => {
        const updatedURL = `${pathname}${searchParams}`
        if (updatedURL !== currentURL && showFormStepper) {
            setShowFormStepper(false)
            setCurrentURL(updatedURL)
        } else if (updatedURL !== currentURL) {
            setCurrentURL(updatedURL)
        }

    }, [pathname, searchParams, URL, showFormStepper, setShowFormStepper, currentURL])

    return null
}