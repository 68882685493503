import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTw = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 18 20"
		{...props}
	>
		<clipPath id="tw_svg__a">
			<path d="M0 0h17.5v20H0z" />
		</clipPath>
		<g clipPath="url(#tw_svg__a)">
			<path d="M2.5 1.25A2.5 2.5 0 0 0 0 3.75v12.5c0 1.379 1.121 2.5 2.5 2.5H15c1.379 0 2.5-1.121 2.5-2.5V3.75c0-1.379-1.121-2.5-2.5-2.5zm11.606 3.281L10.05 9.164l4.77 6.305h-3.735l-2.922-3.825-3.348 3.825H2.961l4.336-4.957-4.574-5.98H6.55l2.644 3.495 3.055-3.496zm-1.477 9.828L5.992 5.582H4.887l6.71 8.777h1.028z" />
		</g>
	</svg>
);
export default SvgTw;
