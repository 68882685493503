import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsRental = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M62.62 15c-12.36 0-22.38 10.02-22.38 22.38 0 1.8.22 3.56.62 5.24L15 68.48V85h16.52v-8.26h8.26v-8.26h8.26s5.48-5.26 9.63-9.28c1.59.36 3.25.56 4.95.56C74.98 59.76 85 49.74 85 37.38S74.98 15 62.62 15m-4.4 41.76-1.32-.3-.97.94c-3.23 3.13-7.27 7.01-8.89 8.58h-9.75v8.26h-8.26v8.26H17.5V69.52l25.13-25.13.99-.99-.33-1.36c-.37-1.53-.55-3.1-.55-4.65 0-3.77 1.08-7.29 2.91-10.3l27.27 27.27c-3.01 1.83-6.53 2.91-10.3 2.91-1.47 0-2.95-.17-4.4-.5zm16.79-3.86L47.1 24.99c3.65-4.56 9.24-7.49 15.52-7.49 10.96 0 19.88 8.92 19.88 19.88 0 6.28-2.93 11.87-7.49 15.52"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#003049"
			d="m46.56 49.08-8.19 8.19a1.25 1.25 0 0 0 0 1.77c.24.24.56.37.88.37s.64-.12.88-.37l8.19-8.19c.49-.49.49-1.28 0-1.77a1.25 1.25 0 0 0-1.77 0zM68.46 25.73a5.71 5.71 0 0 0-5.7 5.7c0 3.14 2.56 5.7 5.7 5.7s5.7-2.56 5.7-5.7-2.56-5.7-5.7-5.7m0 8.9a3.21 3.21 0 0 1-3.2-3.2c0-1.76 1.44-3.2 3.2-3.2s3.2 1.44 3.2 3.2-1.44 3.2-3.2 3.2"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsRental;
