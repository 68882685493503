import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapPin = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 20 20"
		{...props}
	>
		<g clipPath="url(#map-pin_svg__a)">
			<path
				fill="#fff"
				d="M10 19.773 4.697 14.47a7.5 7.5 0 1 1 10.606 0zm4.125-6.482a5.833 5.833 0 1 0-8.25 0L10 17.416zM10 10.833A1.667 1.667 0 1 1 10 7.5a1.667 1.667 0 0 1 0 3.334"
				style={{
					fill: '#fff',
					fillOpacity: 1,
				}}
			/>
		</g>
		<defs>
			<clipPath id="map-pin_svg__a">
				<path
					fill="#fff"
					d="M0 0h20v20H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
				/>
			</clipPath>
		</defs>
	</svg>
);
export default SvgMapPin;
