import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownArrowSelect = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 25"
		{...props}
	>
		<g clipPath="url(#down-arrow-select_svg__a)">
			<path
				fill="#003049"
				d="M16.59 9.09 12 13.67 7.41 9.09 6 10.5l6 6 6-6z"
				style={{
					fill: '#003049',
					fillOpacity: 1,
				}}
			/>
		</g>
		<defs>
			<clipPath id="down-arrow-select_svg__a">
				<path
					fill="#fff"
					d="M0 0h24v24H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
					transform="translate(0 .5)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default SvgDownArrowSelect;
