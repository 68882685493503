import { logger } from "../logger";

type Optional = {
	<V, K extends string>(key: K, value?: V, defaultValue?: undefined): {
		[key in K]?: V;
	};
	<V, K extends string>(key: K, value?: V, defaultValue?: V): {
		[key in K]: V;
	};
};

/** If a value is empty string or null, normalize it to undefined */
const normalizeUnset = <T>(value?: T) => ['', undefined, null].some(i => value === i) ? undefined : value;

/** Does not warn when setting is not provided.  Allows for a default fallback value */
export const optional: Optional = (key, value, defaultValue) => ({ [key]: normalizeUnset(value) ?? defaultValue });

/** Warns when setting is not provided */
export const required = <T, K extends string>(key: K, value?: T) => {
	value = normalizeUnset(value);
	if (value === undefined) {
		logger.warn('Required env var not found: ' + key);
	}

	// follow the same pattern as optional from here
	return optional(key, value, value);
};

/** Evaluates to true if the value is "true" or "1".  Case insensitive */
export const isTrue = <K extends string>(key: K, value = '') =>
	({ [key]: /^\s*(true|1)\s*$/i.test(value) } as { [key in K]: boolean });

/** Check that the value is set and that a valid number is parsed */
export const toNumber = (value?: string) => normalizeUnset(value) !== undefined && !isNaN(Number(value)) ? Number(value) : undefined;
