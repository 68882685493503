import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#B30015"
			d="M1 21 12 2l11 19zm3.45-2h15.1L12 6zM12 18q.424 0 .713-.288A.97.97 0 0 0 13 17a.97.97 0 0 0-.287-.712A.97.97 0 0 0 12 16a.97.97 0 0 0-.713.288A.97.97 0 0 0 11 17q0 .424.287.712.288.288.713.288m-1-3h2v-5h-2z"
		/>
	</svg>
);
export default SvgWarning;
