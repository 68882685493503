'use client';
import { ApolloLink, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
	ApolloNextAppProvider,
	NextSSRApolloClient,
	NextSSRInMemoryCache,
	SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support/ssr';

// uncomment the following line to enable debug logging
// import { setVerbosity } from "ts-invariant";
// setVerbosity("debug");

function makeClient(props: { version: string, spaceId: string, envId: string, authToken: string, logRequests: boolean }) {
	const httpLink = new HttpLink({
		uri: `https://graphql.contentful.com/content/${props.version}/spaces/${props.spaceId}/environments/${props.envId}`,
		credentials: 'same-origin',
		headers: {
			Authorization: `Bearer ${props.authToken}`,
		},
	});

	const loggerLink = props.logRequests
		? new ApolloLink((operation, forward) => {
			console.log(`[GraphQL request]: ${operation.operationName}`, operation);
			return forward(operation);
		})
		: null;

	const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
		if (graphQLErrors)
			graphQLErrors.forEach(({ message, locations, path }) =>
				console.log(
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
				)
			);

		if (networkError) {
			console.log(`[Network error]: ${networkError}`, {
				operation: JSON.stringify(operation, null, 2),
			});
		}
	});

	const stripDeferLink =
		typeof window === 'undefined' && new SSRMultipartLink({ stripDefer: true });

	const links = [loggerLink, errorLink, stripDeferLink, httpLink].filter(
		Boolean
	);

	return new NextSSRApolloClient({
		cache: new NextSSRInMemoryCache(),
		link: ApolloLink.from(links as any),
		defaultOptions: {
			query: {
				fetchPolicy: 'network-only'
			}
		},
	});
}

export function ApolloWrapper(props: Parameters<typeof makeClient>[0] & { children: React.ReactNode }) {
	return (
		<ApolloNextAppProvider makeClient={() => makeClient(props)}>
			{props.children}
		</ApolloNextAppProvider>
	);
}
