import { CalendlyButton } from '../CalendlyButton/CalendlyButton';
import StepperButton from '../stepper-button/StepperButton';
import Button from '../utility/button/button';
import { CtaLink } from './types';

export const HeaderCtaSection = (props: { 
  ctaButtons: CtaLink[], 
  className: string,
  containsStartAQuoteButton: boolean,
  containsBookACallButton: boolean,
  heightVariant?: 'narrow',
  onClick?: () => void
}) => {
  return (
    <div
      className={props.className}
      onClick={props.onClick}
    >
      {props.ctaButtons?.map((item, index) => (
        <Button
          key={index}
          title={item.title}
          href={item.buttonUrl}
          textColor={item.textColour}
          backgroundColor={item.backgroundColour}
          borderColor={item.borderColour}
          isMenuButton={true}
          heightVariant={props.heightVariant}
          type={item.type}
          openInNewTab={item.openInNewTab ?? false}
        />
      ))}
      {props.containsStartAQuoteButton && (
        <StepperButton title="Start a Quote" isMenuButton heightVariant={props.heightVariant} />
      )}
      {props.containsBookACallButton && (
        <CalendlyButton title="Book a Call" isMenuButton heightVariant={props.heightVariant} />
      )}
    </div>
  );
};
