import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNavigator = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			stroke="#003049"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2.5}
			d="M6 18 19 5m0 0h-8.667M19 5v8.667"
			style={{
				stroke: '#003049',
				strokeOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgNavigator;
