'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { getCookie, setCookie } from 'cookies-next';
import { OptionsType } from 'cookies-next/lib/types';
import { MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { GET_ANNOUNCEMENT_BAR } from '../../queries';
import { useAppContext } from '../form-stepper/StepperProvider';
import styles from './AnnouncementBar.module.scss';

export interface AnnouncementBarProps {
	announcementBarCollection: {
		items: {
			annoncement: {
				json: any;
			};
			hours: number;
		}[];
	};
}

export function AnnouncementBar(props: {
	customClass: string;
	pageCategories: string[];
}) {
	const announcementRef = useRef<HTMLDivElement>(null);
	const { setAnnouncementBannerHeight } = useAppContext() ?? {};
	const [closed, setClosed] = useState<boolean>(false);
	const [cookieFetched, setCookieFetched] = useState<boolean>(false);

	useEffect(() => {
		if (announcementRef.current) {
			setAnnouncementBannerHeight(announcementRef.current.clientHeight);
		}
	}, [announcementRef]);

	useEffect(() => {
		// Check the cookie to determine if the announcement is closed
		const announcementClosedCookie = getCookie('announcementClosed');

		setClosed(announcementClosedCookie ? true : false);
		announcementClosedCookie ? setAnnouncementBannerHeight(0) : null;
		setCookieFetched(true);
	}, []);

	const { data } = useSuspenseQuery<AnnouncementBarProps>(
		GET_ANNOUNCEMENT_BAR,
		{
			variables: {
				showOnPages: [...props.pageCategories, 'Everywhere'],
			},
		}
	);

	const options = (): Options => {
		const sizedText = (size: number) => (node: any, children: ReactNode) =>
			<span className={styles[`size-${size}`]}>{children}</span>;

		return {
			renderNode: {
				[BLOCKS.HEADING_1]: sizedText(1),
				[BLOCKS.HEADING_2]: sizedText(2),
				[BLOCKS.HEADING_3]: sizedText(3),
				[BLOCKS.HEADING_4]: sizedText(4),
				[BLOCKS.HEADING_5]: sizedText(5),
				[BLOCKS.HEADING_6]: sizedText(6),
			},
		};
	}

	// TODO(khaney): spelling error
	const announcement = data?.announcementBarCollection?.items[0]?.annoncement;
	const reappearHour = data?.announcementBarCollection?.items[0]?.hours;

	const closeAnnouncementBar = (hours: number) => {
		// Set a cookie to remember the closed state and reappearing duration
		const expires = hours
			? new Date(Date.now() + hours * 60 * 60 * 1000)
			: undefined;

		setCookie('announcementClosed', true, { expires: expires } as OptionsType);
		setClosed(true);
		setAnnouncementBannerHeight(0);
	};

	const processLink = (e: MouseEvent) => {
		if (e.target instanceof HTMLAnchorElement) {
			e.preventDefault();
			e.stopPropagation();
			window.open(e.target.href, '_blank');
		}
	};

	return (!announcement?.json || closed) ? null : (
		<div
			className={`${styles['announcementBar']} ${styles[props.customClass]} ${
				cookieFetched
					?''
					: styles['announcementBar--hidden']
			}`}
			ref={announcementRef}
		>
			<div className={styles['content']} onClick={processLink}>
				{documentToReactComponents(announcement?.json, options())}
			</div>

			<button
				className={styles['close-btn']}
				onClick={() => {
					closeAnnouncementBar(reappearHour);
				}}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
				>
					<path
						d="M12 4L4 12M4 4L12 12"
						stroke="#003049"
						strokeWidth="1.33333"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
				<span>Close</span>
			</button>
		</div>
	);
}

export default AnnouncementBar;
