import { logSystem, Severity } from './logSystem';

export const createLogger = () => {
	const log = (
		severity: Severity,
		tags: string | string[],
		message: unknown[] = []
	) => {
		logSystem.emit({
			severity,
			tags: [tags].flat(),
			message: message.map(String),
		});
	};

	const methods = (tags: string | string[] = []) => ({
		debug: (...message: unknown[]) => log('debug', tags, message),
		info: (...message: unknown[]) => log('info', tags, message),
		warn: (...message: unknown[]) => log('warn', tags, message),
		error: (...message: unknown[]) => log('error', tags, message),

		tag: (tags: string[]) => {
			const { tag, ...taggedLogger } = methods(tags);
			return taggedLogger;
		},
	});

	return methods([]);
};

export const logger = createLogger();
