import Link from "next/link";
import { CalendlyButton } from "../../CalendlyButton/CalendlyButton";
import { ActionLink, MenuItem } from "../types";
import styles from "./header-mobile-menu.module.scss";

interface MobileMenuProps {
  menuHeight: number
  menuContent: MenuItem[]
  ctaSection: JSX.Element
  activeMenuItem: MenuItem | null
  onItemSelected: (menuItem: MenuItem) => void
  onBackClick: () => void
  activeBack: boolean // can we derive this?
  onCloseClick: () => void
  actionLinks: ActionLink[]
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ menuContent, ctaSection, menuHeight, activeMenuItem, onItemSelected, onBackClick, activeBack, onCloseClick, actionLinks }) => (
  <div
    className={styles.megaMenuContentsContainer}
    style={{ maxHeight: `${menuHeight}px` }}
  >
    <div className={styles.menuOptionsConatiner}>
      <div
        className={`${styles.menuMobileHeading} ${styles['menuMobileHeading--subheading']}`}
      >
        <h5>Menu</h5>
      </div>

      <ul
        className={styles.menuMobileContentWrapper}
        style={{ maxHeight: `calc(${menuHeight}px - 258px)` }}
        data-lenis-prevent
      >
        {menuContent.map((menuItem: MenuItem, index: number) => (
          <li
            key={index}
            className={styles.subMenuMobileContent}
            data-menu-index={index}
          >
            {menuItem.__typename === 'MenuItem' ? (
              <>
                <span
                  className={styles.menuItemDropdown}
                  tabIndex={activeMenuItem ? -1 : 0}
                  onClick={() => onItemSelected(menuItem)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      onItemSelected(menuItem);
                    }
                  }}
                >
                  {menuItem.title}
                  {/* chevron left */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M6 12L10 8L6 4"
                      stroke="white"
                      strokeWidth="1.33333"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <div
                  tabIndex={menuItem === activeMenuItem ? 0 : -1}
                  className={`${styles.subMenuMobileContentWrapper} ${menuItem === activeMenuItem
                      ? styles['subMenuMobileContentWrapper--isActive']
                      : ''
                    } ${activeBack &&
                    styles['subMenuMobileContentWrapper--isInactive']
                    }`}
                  style={{ maxHeight: `calc(${menuHeight}px)` }}
                >
                  <div
                    className={`${styles.subMenuMobileHeading} ${styles['subMenuMobileHeading--subheading']} ${styles['backBtn']}`}
                  >
                    <div
                      className={styles.backBtn}
                      onClick={onBackClick}
                    >
                      <div className={styles.backBtnContainer}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M10 4L6 8L10 12"
                            stroke="#8291AC"
                            style={{
                              stroke: '#8291AC',
                              strokeOpacity: 1,
                              strokeWidth: 1.33333,
                              strokeLinecap: 'round',
                              strokeLinejoin: 'round',
                            }}
                          />
                        </svg>
                        <button tabIndex={activeMenuItem ? 0 : -1}>
                          Back
                        </button>
                      </div>
                    </div>
                    <h5>{activeMenuItem?.title}</h5>
                  </div>

                  <ul className={styles.subMenuList}>
                    {menuContent[index]?.subMenuCollection?.items?.map(
                      (item, num) => (
                        <li className={styles.subMenuMobileContent} key={num}>
                          <span className={styles.menuItemLink}>
                            {!item.containsBookACallFunctionality ? (
                              <Link
                                href={item.url}
                                onClick={onCloseClick}
                                tabIndex={activeMenuItem === item ? 0 : -1}
                              >
                                {item.text}
                              </Link>
                            ) : (
                              <CalendlyButton title={item.text} tabIndex={activeMenuItem === item ? 0 : -1} className={styles['menu-list-mobile']} isMenuButton />
                            )}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                  {activeMenuItem === menuItem && (
                    <div className={styles.menuActionButtonsContainer}>
                      {!activeMenuItem?.hideMenuActionSection && (
                        <div className={styles.subMenuItemFooter}>
                          {actionLinks.map((menuItem, index) => (
                            <Link
                              href={menuItem.url}
                              key={index}
                              onClick={onCloseClick}
                            >
                              {menuItem.text}
                            </Link>
                          ))}
                        </div>
                      )}
                      {ctaSection}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <span className={styles.menuItemLink}>
                {!menuItem.containsBookACallFunctionality ? (
                  <Link
                    href={menuItem.url}
                    onClick={onCloseClick}
                  >
                    {menuItem.text}
                  </Link>
                ) : (
                  // One of the inline items is an unstyled CalendlyButton                  
                  <CalendlyButton title={menuItem.text} />
                )}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
    <div className={styles.menuActionButtonsContainer}>
      {ctaSection}
    </div>
  </div>
);
