import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsAuto = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M84.12 70.66H17.24a1.25 1.25 0 0 0 0 2.5h66.89a1.25 1.25 0 0 0 0-2.5zM56.24 82.67h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M29.24 82.67h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M83.24 82.67h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M70.79 37.76l-9.21-9.21A5.3 5.3 0 0 0 57.83 27h-22.4c-1.78 0-3.43.89-4.42 2.37l-5.53 8.32h-6.43c-2.24 0-4.05 1.81-4.05 4.05v11.92l8.47 1.81c.14 3.85 3.3 6.94 7.19 6.94s7-3.05 7.18-6.86h25.9c.18 3.81 3.33 6.86 7.18 6.86s7-3.05 7.18-6.86h7.76V41.51l-15.09-3.77zm-10.97-7.44 7.35 7.35H48.92V29.5h8.91c.75 0 1.45.29 1.98.82zm-26.71.43c.52-.78 1.39-1.25 2.33-1.25h10.98v8.17H28.5l4.6-6.92zm-2.44 29.17c-2.47 0-4.49-1.93-4.67-4.36 0-.12-.03-.23-.03-.34 0-.78.21-1.51.55-2.16.78-1.5 2.34-2.54 4.15-2.54s3.37 1.04 4.15 2.54c.34.65.55 1.38.55 2.16 0 .12-.03.23-.03.34-.18 2.43-2.19 4.36-4.67 4.36m35.6-4.36c0-.12-.03-.23-.03-.34 0-.78.21-1.51.55-2.16.78-1.5 2.34-2.54 4.15-2.54s3.37 1.04 4.15 2.54c.34.65.55 1.38.55 2.16 0 .12-.03.23-.03.34-.18 2.43-2.19 4.36-4.67 4.36s-4.49-1.93-4.67-4.36m17.11-2.5H77.8a7.21 7.21 0 0 0-6.87-5.04 7.21 7.21 0 0 0-6.87 5.04H37.53a7.21 7.21 0 0 0-6.87-5.04c-3.22 0-5.91 2.09-6.84 4.98l-6.31-1.35v-9.9c0-.86.7-1.55 1.55-1.55h6.43c.41 0 .81-.1 1.17-.29v.27h43.5s.02 0 .03.01l13.19 3.3v9.59z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsAuto;
