import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsLife = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<mask
			id="Insurance_Icons-Life_svg__a"
			width={52}
			height={69}
			x={24}
			y={16}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'luminance',
			}}
		>
			<path
				fill="#fff"
				d="M75.64 16H24v68.84h51.64z"
				style={{
					fill: '#fff',
					fillOpacity: 1,
				}}
			/>
		</mask>
		<g fill="#003049" mask="url(#Insurance_Icons-Life_svg__a)">
			<path
				d="M74.39 16H37.94s-.05.01-.08.02c-.11 0-.22.03-.32.06-.04.01-.08.02-.12.04-.13.06-.26.14-.37.24l-12.68 12.7c-.11.11-.18.23-.24.37-.02.04-.03.08-.04.12-.04.1-.06.21-.06.32 0 .03-.02.05-.02.08v53.64c0 .69.56 1.25 1.25 1.25H74.4c.69 0 1.25-.56 1.25-1.25V17.25c0-.69-.56-1.25-1.25-1.25zm-1.25 66.34H26.5V31.19h11.44c.69 0 1.25-.56 1.25-1.25V18.5h33.94v63.84zM28.27 28.69l4.21-4.21 4.21-4.21v8.43h-8.42z"
				style={{
					fill: '#003049',
					fillOpacity: 1,
				}}
			/>
			<path
				d="M31.01 36.8c0 .69.56 1.25 1.25 1.25h5.45a15.25 15.25 0 0 0-3.06 7.25h-2.39a1.25 1.25 0 0 0 0 2.5h2.25c.07 2.64.8 5.1 2.03 7.25h-4.28a1.25 1.25 0 0 0 0 2.5h6.09c.81.91 1.72 1.73 2.72 2.43v4.82h-8.8a1.25 1.25 0 0 0 0 2.5h8.8v5.96a1.247 1.247 0 0 0 1.25 1.25c.22 0 .43-.05.62-.16l7-3.99 7 3.99c.39.22.86.22 1.25 0 .38-.22.62-.64.62-1.08v-5.96h8.58a1.25 1.25 0 0 0 0-2.5h-8.58v-4.99c.91-.66 1.74-1.42 2.49-2.26h6.09a1.25 1.25 0 0 0 0-2.5h-4.28a15.1 15.1 0 0 0 2.03-7.25h2.25a1.25 1.25 0 0 0 0-2.5H65a15.25 15.25 0 0 0-3.06-7.25h5.45a1.25 1.25 0 0 0 0-2.5h-7.87a15.27 15.27 0 0 0-9.69-3.46c-3.67 0-7.05 1.3-9.69 3.46h-7.87c-.69 0-1.25.56-1.25 1.25zm18.81-2.21c1.72 0 3.36.35 4.86.96 1.45.59 2.75 1.45 3.88 2.5 2.02 1.89 3.43 4.41 3.9 7.25.12.69.19 1.4.19 2.12 0 .13-.02.25-.02.38-.08 2.71-1 5.21-2.52 7.25-.4.54-.83 1.04-1.3 1.51q-.54.525-1.14.99l-.11.08a12.775 12.775 0 0 1-7.73 2.62c-2.36 0-4.4-.6-6.26-1.64-.43-.24-.85-.52-1.25-.81l-.34-.25a13.155 13.155 0 0 1-2.44-2.5 12.72 12.72 0 0 1-2.52-7.25c0-.13-.02-.25-.02-.38 0-.72.07-1.43.19-2.12.48-2.84 1.88-5.36 3.9-7.25 1.13-1.05 2.43-1.91 3.88-2.5 1.5-.62 3.14-.96 4.86-.96zM56.3 64.8v6.31l-5.75-3.27c-.38-.22-.85-.22-1.24 0l-5.75 3.27V61.4c1.91.86 4.03 1.35 6.26 1.35s4.51-.53 6.48-1.46z"
				style={{
					fill: '#003049',
					fillOpacity: 1,
				}}
			/>
			<path
				d="M46.56 52.78c.23.23.55.37.88.37h.02c.34 0 .66-.15.89-.4l4.57-4.95 2.31-2.5 2.06-2.23c.47-.51.44-1.3-.07-1.77a1.25 1.25 0 0 0-1.77.07l-1.85 2.01-1.77 1.92-2.31 2.5-2.12 2.3-2.3-2.3-.61-.61-.36-.36a1.25 1.25 0 0 0-1.77 0c-.27.27-.37.62-.35.98.02.29.13.57.35.79l4.19 4.19z"
				style={{
					fill: '#003049',
					fillOpacity: 1,
				}}
			/>
		</g>
	</svg>
);
export default SvgInsuranceIconsLife;
