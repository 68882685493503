import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYoutubeFill = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M20.595 4.46a2.76 2.76 0 0 1 1.945 1.945C22.998 8.12 23 11.7 23 11.7s0 3.58-.46 5.296a2.76 2.76 0 0 1-1.945 1.945C18.88 19.4 12 19.4 12 19.4s-6.88 0-8.595-.46a2.76 2.76 0 0 1-1.945-1.945C1 15.28 1 11.7 1 11.7s0-3.58.46-5.295A2.76 2.76 0 0 1 3.405 4.46C5.12 4 12 4 12 4s6.88 0 8.595.46m-5.082 7.24L9.798 15V8.401z"
			clipRule="evenodd"
			style={{
				fill: '#fff',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgYoutubeFill;
