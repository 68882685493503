export const toCamelCaseObj = (pascalCaseObj: any): any => {
	if (pascalCaseObj === null || pascalCaseObj === undefined) {
		return pascalCaseObj;
	}

	if (Array.isArray(pascalCaseObj)) {
		return toCamelCaseArray(pascalCaseObj);
	}

	const camelCaseObj: any = {};

	Object.keys(pascalCaseObj).forEach((key) => {
		const camelCaseKey = key[0].toLowerCase() + key.slice(1);
		camelCaseObj[camelCaseKey] =
			typeof pascalCaseObj[key] === 'object'
				? toCamelCaseObj(pascalCaseObj[key])
				: pascalCaseObj[key];
	});

	return camelCaseObj;
};

const toCamelCaseArray = (pascalCaseArray: any[]): any[] =>
	pascalCaseArray.map((item) =>
		typeof item === 'object' ? toCamelCaseObj(item) : item
	);

export const slugify = (text: string) => {
	return String(text ?? '')
		.toLowerCase()
		.replace(/\s+/g, '-') // Replace spaces with -
		.replace(/[^\w-]+/g, '') // Remove all non-word chars
		.replace(/--+/g, '-'); // Replace multiple - with single -
};

/** Returns grouped array, sorted ascending by key */
export const groupBy = <T>(s: T[], keyFn: (t: T) => string): Map<string, T[]> => {
  const map = new Map<string, T[]>();
  s.forEach(t => {
    const key = keyFn(t);
    if (!map.has(key)) {
      map.set(key, new Array<T>());
    }
    map.get(key)?.push(t);
  });
  for (const key of map.keys()) {
    map.get(key)?.sort((a, b) => keyFn(a).localeCompare(keyFn(b)));
  }
  map.forEach(value => value.sort((a, b) => keyFn(a).localeCompare(keyFn(b))));
  return map;
}
