import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlayButton = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 55 55"
		{...props}
	>
		<circle
			cx={27.5}
			cy={27.5}
			r={27.5}
			fill="#F0FF00"
			style={{
				fill: '#f0ff00',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#003049"
			d="M38.672 26.059a1.638 1.638 0 0 1 0 2.882l-15.469 8.646c-1.146.64-2.578-.16-2.578-1.44V18.853c0-1.281 1.432-2.081 2.578-1.441z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgPlayButton;
