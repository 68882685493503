import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCoverIcon1 = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#F0FF00"
			d="M81.96 51.43c.24.24.56.37.88.37s.64-.12.88-.37c.49-.49.49-1.28 0-1.77l-9.19-9.19-24.1-24.1a1.25 1.25 0 0 0-1.77 0L37.18 27.85v-5.66c0-2.49-2.03-4.52-4.52-4.52H28.7c-2.49 0-4.52 2.03-4.52 4.52v18.66l-8.81 8.81a1.25 1.25 0 0 0 0 1.77c.49.49 1.28.49 1.77 0l7.06-7.05V65.3c-1.99-.68-4.4-1.17-7.81-1.17a1.25 1.25 0 0 0 0 2.5c4.01 0 6.44.73 8.57 1.65.63.27 1.24.56 1.85.86 2.82 1.35 5.74 2.76 11.51 2.76s8.69-1.4 11.51-2.76c2.69-1.29 5.23-2.51 10.43-2.51s7.74 1.22 10.43 2.51c2.82 1.35 5.74 2.76 11.51 2.76a1.25 1.25 0 0 0 0-2.5c-3.23 0-5.44-.47-7.29-1.14V44.38l7.06 7.05zM26.69 22.18c0-1.11.9-2.02 2.02-2.02h3.96c1.11 0 2.02.9 2.02 2.02v8.08s.01.04.01.07l-8.01 8.01zM51.9 65.49v-7.17c0-.31.25-.57.57-.57h8.99c.31 0 .57.25.57.57v5.85c-.56-.03-1.15-.05-1.77-.05-3.73 0-6.26.59-8.35 1.36zm20.5 1.7c-.21-.1-.43-.2-.64-.3-2.02-.97-4.1-1.96-7.24-2.45v-6.11c0-1.69-1.38-3.07-3.07-3.07h-8.99c-1.69 0-3.07 1.38-3.07 3.07v8.25c-.22.1-.44.21-.66.31-2.69 1.29-5.23 2.51-10.43 2.51s-7.74-1.22-10.43-2.51q-.585-.285-1.2-.57V41.88l22.88-22.86 22.86 22.86v25.31zM82.19 82.49c-5.2 0-7.74-1.22-10.43-2.51-2.82-1.35-5.74-2.76-11.51-2.76s-8.69 1.4-11.51 2.76c-2.69 1.29-5.23 2.51-10.43 2.51s-7.74-1.22-10.43-2.51c-2.82-1.35-5.74-2.76-11.51-2.76a1.25 1.25 0 0 0 0 2.5c5.2 0 7.74 1.22 10.43 2.51 2.82 1.35 5.74 2.76 11.51 2.76s8.69-1.4 11.51-2.76c2.69-1.29 5.23-2.51 10.43-2.51s7.74 1.22 10.43 2.51c2.82 1.35 5.74 2.76 11.51 2.76a1.25 1.25 0 0 0 0-2.5"
			style={{
				fill: '#f0ff00',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgCoverIcon1;
