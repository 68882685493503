import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPaginationNext = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 28 28"
		{...props}
	>
		<path d="m10.5 21 7-7-7-7" />
	</svg>
);
export default SvgPaginationNext;
