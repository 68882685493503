import { timingSafeEqual } from "crypto";

export const timingSafeCompare = (a?: string | null, b?: string | null) => {
  
  // Comparisons to empty strings should fail, but we still need
  // to invoke timingSafeEqual to avoid timing attacks.
  if (!a || !b) {
    a = 'a';
    b = 'b';
  }
  
  return timingSafeEqual(
    Buffer.alloc(40, a),
    Buffer.alloc(40, b)
  );
};