import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTask = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 27 35"
		{...props}
	>
		<path
			fill="#2A6100"
			d="M16.833.833H3.5C1.667.833.183 2.333.183 4.167L.167 30.833c0 1.834 1.483 3.334 3.316 3.334H23.5c1.833 0 3.333-1.5 3.333-3.334v-20zm6.667 30h-20V4.167h11.667V12.5H23.5zM8.2 19.25 5.833 21.6l5.9 5.9 9.433-9.433-2.35-2.35-7.066 7.066z"
		/>
	</svg>
);
export default SvgTask;
