import { PlayBtn, SendPlane } from '@bw/elements/icons';
import Link from 'next/link';
import React, { MouseEventHandler } from 'react';
import { ThemeColor, colorMapping, paletteVariants } from '../../../theme';
import styles from './button.module.scss';

export type HeightVariant = 'narrow';

// TODO(khaney): remove individual color mappings and use paletteVariants instead
export type ButtonProps = {
	id?: string;
	title: string;
	containsSubmitIcon?: boolean;
	type?: string;
	openInNewTab?: boolean;
	playIcon?: boolean;
	heightVariant?: HeightVariant;
	onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
	href?: string;
	className?: string;
	tabIndex?: number;
	isMenuButton?: boolean;
	disabled?: boolean;
	fadeUp?: boolean;
} & ({
	backgroundColor: ThemeColor;
	borderColor: ThemeColor;
	textColor: ThemeColor;
	paletteVariant?: never;
} | {
	backgroundColor?: never;
	borderColor?: never;
	textColor?: never;
	paletteVariant: keyof typeof paletteVariants;
});

export const Button: React.FC<ButtonProps> = ({
	id,
	title,
	href,
	textColor = 'deepBlue',
	backgroundColor = 'brightYellow',
	borderColor = 'brightYellow',
	containsSubmitIcon,
	type,
	openInNewTab,
	playIcon,
	heightVariant,
	paletteVariant,
	onClick,
	className,
	tabIndex,
	isMenuButton,
	disabled,
	fadeUp = !isMenuButton,
}) => {
	if (paletteVariant) {
		({ backgroundColor, borderColor, textColor } = paletteVariants[paletteVariant]);
	}
	const buttonStyle: Record<string, string> = {
		'--bg-color': colorMapping[backgroundColor] || backgroundColor,
		'--text-color': colorMapping[textColor] || textColor,
		'--border-color': colorMapping[borderColor] || colorMapping[backgroundColor] || backgroundColor || 'transparent',
	};

	const buttonClassName = [
		styles.button,
		styles[heightVariant ?? ''],
		isMenuButton && styles['button--isMenu'],
		className
	].filter(Boolean).join(' ');

	return (
		<>
			{containsSubmitIcon || playIcon}
			{href
				? (
					<Link
						id={id}
						onClick={onClick}
						className={buttonClassName}
						style={buttonStyle}
						href={href}
						type={type}
						target={openInNewTab ? '_blank' : '_self'}
						aria-label={openInNewTab ? `${title} (opens in a new tab)` : `${title}`}
						data-aos={fadeUp ? 'fade-up' : 'false'}
						tabIndex={tabIndex}

					>
						{playIcon &&
							<PlayBtn className="svg is-icon-right" width={25} height={25} />
						}
						{title}
						{containsSubmitIcon &&
							<SendPlane className="svg" width={25} height={25} />
						}
					</Link>
				)
				: (
					<button
						id={id}
						className={buttonClassName}
						style={buttonStyle}
						onClick={onClick}
						data-aos={fadeUp ? 'fade-up' : 'false'}
						tabIndex={tabIndex}
						disabled={disabled}
					>
						{playIcon &&
							<PlayBtn className="svg is-icon-right" width={25} height={25} />
						}
						{title}
						{containsSubmitIcon &&
							<SendPlane className="svg" width={25} height={25} />
						}
					</button>
				)
			}
		</>
	);
};

export default Button;
