"use client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export function CaptchaWrapper(props: {
  children: React.ReactNode,
  recaptchaSiteKey?: string
}) {
  return (
    props.recaptchaSiteKey
      ? <GoogleReCaptchaProvider
          reCaptchaKey={props.recaptchaSiteKey ?? "undefined"}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined,
          }}
        >
          {props.children}
        </GoogleReCaptchaProvider>

      : props.children
  );
}
