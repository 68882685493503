import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsUmbrella = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M77.84 42.92C77.84 27.52 65.32 15 49.92 15S22 27.52 22 42.92v9.74s.02.06.02.09c0 .08-.02.16-.02.23a1.25 1.25 0 0 0 2.5 0c0-.88 2.16-2.22 5.42-2.22s5.42 1.34 5.42 2.22a1.25 1.25 0 0 0 2.5 0c0-.88 2.16-2.22 5.42-2.22s5.42 1.34 5.42 2.22v24.56c0 3.17-2.58 5.75-5.75 5.75s-5.75-2.58-5.75-5.75a1.25 1.25 0 0 0-2.5 0c0 4.55 3.7 8.25 8.25 8.25s8.25-3.7 8.25-8.25V52.98c0-.88 2.16-2.22 5.42-2.22s5.42 1.34 5.42 2.22a1.25 1.25 0 0 0 2.5 0c0-.88 2.16-2.22 5.42-2.22s5.42 1.34 5.42 2.22a1.25 1.25 0 1 0 2.48-.23c0-.03.02-.06.02-.09v-9.74zM35.31 29.61v19.87c-1.39-.76-3.27-1.21-5.39-1.21s-4.02.46-5.42 1.22v-6.57c0-10.09 5.91-18.83 14.45-22.93a14.52 14.52 0 0 0-3.64 9.62m13.36 19.88c-1.4-.77-3.28-1.22-5.42-1.22s-4.04.46-5.44 1.24v-19.9c0-6.26 4.77-11.42 10.86-12.04V49.5zm13.36.01c-1.4-.77-3.29-1.24-5.44-1.24s-4.02.46-5.42 1.22V17.56c6.09.63 10.86 5.79 10.86 12.04v19.9m13.31-.01c-1.4-.77-3.28-1.22-5.42-1.22s-4 .45-5.39 1.21V29.61c0-3.68-1.38-7.05-3.64-9.62 8.54 4.1 14.45 12.84 14.45 22.93z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsUmbrella;
