import React from 'react';
import { ThemeColor } from '../../../theme';
import Button from '../button/button';

interface FormButtonProps {
	id?: string;
	title: string;
	className?: string;
	textColor?: ThemeColor;
	backgroundColor?: ThemeColor;
	borderColor?: ThemeColor;
	type?: 'button' | 'submit' | 'reset';
	containsSubmitIcon?: boolean;
	isDisabled?: boolean;
	onClick?: (item: any) => void;
}

const FormButton: React.FC<FormButtonProps> = ({
	id,
	title,
	className = '',
	type = 'submit',
	onClick = () => void 0,
	containsSubmitIcon,
	isDisabled,
}) => {
	return (
		<Button
			id={id}
			className={className}
			type={type}
			disabled={isDisabled}
			onClick={onClick}
			paletteVariant='primary'
			containsSubmitIcon={containsSubmitIcon}
			title={title}
			fadeUp={false}
		/>
	);
};

export default FormButton;
