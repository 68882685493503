import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsHomeWarranty = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<mask
			id="Insurance_Icons-Home_Warranty_svg__a"
			width={74}
			height={74}
			x={13}
			y={13}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: 'luminance',
			}}
		>
			<path
				fill="#fff"
				d="M86.55 13H13v73.54h73.55z"
				style={{
					fill: '#fff',
					fillOpacity: 1,
				}}
			/>
		</mask>
		<g mask="url(#Insurance_Icons-Home_Warranty_svg__a)">
			<path
				fill="#003049"
				d="M86.18 48.88 76.14 38.84v-14.2c0-.69-.56-1.25-1.25-1.25h-14.2L50.66 13.35c-.47-.47-1.3-.47-1.77 0L38.85 23.39h-14.2c-.69 0-1.25.56-1.25 1.25v14.2L13.37 48.88a1.25 1.25 0 0 0 0 1.77l10.04 10.04v14.2c0 .69.56 1.25 1.25 1.25h14.2L48.9 86.18c.24.24.56.37.88.37s.64-.12.88-.37L60.7 76.14h14.2c.69 0 1.25-.56 1.25-1.25v-14.2l10.04-10.04c.49-.49.49-1.28 0-1.77zM59.2 74.09l-6.77 6.77V62.3c0-.32.26-.58.58-.58h5.62c.32 0 .58.26.58.58v11.8zm7.62-.45H61.7V62.3c0-1.7-1.38-3.08-3.08-3.08H53c-1.7 0-3.08 1.38-3.08 3.08v21.06l-.16.16L40.24 74c-.23-.23-.55-.37-.88-.37h-5.44V49.86l16.45-16.45 16.45 16.45v23.78m-32.9-27.31V35.27c0-.63.52-1.15 1.15-1.15h2.91c.63 0 1.15.52 1.15 1.15v5.86l-5.2 5.2zM74 59.29c-.23.23-.37.55-.37.88v13.46h-4.32V52.36l2.07 2.07c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77l-4.2-4.2-17.7-17.7c-.47-.47-1.3-.47-1.77 0l-7.86 7.86v-3.36c0-2.01-1.64-3.65-3.65-3.65h-2.91c-2.01 0-3.65 1.64-3.65 3.65v13.56l-3.84 3.84a1.25 1.25 0 0 0 0 1.77c.24.24.56.37.88.37s.64-.12.88-.37l2.07-2.07v21.27h-5.52V60.17c0-.33-.13-.65-.37-.88l-9.52-9.52 9.52-9.52c.23-.23.37-.55.37-.88V25.91h13.46c.33 0 .65-.13.88-.37l9.52-9.52 9.52 9.52c.23.23.55.37.88.37H73.6v13.46c0 .33.13.65.37.88l9.52 9.52-9.52 9.52z"
				style={{
					fill: '#003049',
					fillOpacity: 1,
				}}
			/>
		</g>
	</svg>
);
export default SvgInsuranceIconsHomeWarranty;
