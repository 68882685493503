import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNextArrow = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 16 16"
		{...props}
	>
		<path
			stroke="#003049"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M1 8h14m0 0L8 1m7 7-7 7"
			style={{
				strokeOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgNextArrow;
