"use client";
import { Check } from '@bw/elements/icons';
import { InputHTMLAttributes } from 'react';
import { FieldError, UseFormReturn, useWatch } from 'react-hook-form';
import { FORM_ERROR } from '../../../constants';
import { ConditionalFieldError } from '../errors';
import styles from './formRadioInputs.module.scss';

interface FormRadioInputListProps  {
  context: UseFormReturn<any>,
  id: string,
  required?: boolean,
  className?: string,
  formError?: string,
  placeholder?: string,
  children?: any;
  classNameItem?: string;
  options: Array<{
    value: any,
    label: string,
  }>;
}

export const FormRadioInputList = ({
  context: { register, control, formState },
  id,
  required = false,
  className = styles.radioInputList,
  classNameItem = styles.radioInputListItem,
  formError = FORM_ERROR.REQUIRED,
  options,
  children,
  ...inputProps
} : FormRadioInputListProps & InputHTMLAttributes<HTMLInputElement>,
) => {
  const selectedValue: boolean = useWatch({ control,  name: id });

  return (
    <div className={className}>
      {children}
      <label htmlFor={id}>
          {options.map((opt, idx) => {
            const innerId = `${id}_${idx}`;
            const isChecked: boolean = selectedValue === opt.value;
            
            return (
              <div key={innerId} className={classNameItem}>
                <input
                  id={innerId}
                  value={opt.value}
                  type="radio"
                  checked={isChecked}
                  required={required}
                  {...register(id, {
                    required: required ? formError : undefined,
                  })}
                  {...inputProps}
                />
                <label htmlFor={innerId}>
                  <span className={isChecked ? styles.checkmark : styles.checkempty}>
                    <span className={styles.icon}>
                      <Check height={18} width={18} />
                    </span>
                  </span>
                  {opt.label}
                </label>
                <ConditionalFieldError error={formState.errors?.[innerId] as FieldError} data-aos="fade-up"/>
              </div>
            );
          })}
        </label>
      
    </div>
  )
}
