import * as React from 'react';
import type { SVGProps } from 'react';
const SvgIn = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 18 20"
		{...props}
	>
		<clipPath id="in_svg__a">
			<path d="M.5 0H18v20H.5z" />
		</clipPath>
		<g clipPath="url(#in_svg__a)">
			<path d="M16.75 1.25H1.746C1.06 1.25.5 1.816.5 2.512v14.976c0 .696.559 1.262 1.246 1.262H16.75c.688 0 1.25-.566 1.25-1.262V2.512c0-.696-.562-1.262-1.25-1.262m-10.96 15H3.194V7.898h2.598v8.352zM4.491 6.758a1.505 1.505 0 0 1 0-3.008c.828 0 1.504.676 1.504 1.504 0 .832-.672 1.504-1.504 1.504m11.02 9.492h-2.594v-4.062c0-.97-.02-2.215-1.348-2.215-1.351 0-1.558 1.054-1.558 2.144v4.133H7.418V7.898h2.488V9.04h.035c.348-.656 1.196-1.348 2.457-1.348 2.625 0 3.114 1.73 3.114 3.98z" />
		</g>
	</svg>
);
export default SvgIn;
