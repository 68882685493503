'use client';
import { useSuspenseQuery } from '@apollo/experimental-nextjs-app-support/ssr';
import { Image } from '@bw/elements';
import { Logo } from '@bw/elements/icons';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { GET_HEADER_ITEMS } from '../../queries';
import styles from './header-slim.module.scss';

interface HeaderSlimData {
  headerCollection: {
    __typename: string;
    total: number;
    items: HeaderSlim[];
  };
}
interface HeaderSlim {
  __typename: string;
  title: string;
  containsStartAQuoteButton: boolean;
  containsBookACallButton: boolean;
  logo: {
    __typename: string;
    title: string;
    imageUrl: string;
    altText: string;
    image: {
      __typename: string;
      title: string;
      description: string;
      url: string;
      width: number;
      height: number;
    };
  };
}

export function SiteHeaderSlim(props) {
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const { data } = useSuspenseQuery<HeaderSlimData>(GET_HEADER_ITEMS, {
    variables: {
      component: props.name as string,
    },
  });
  const pageData = data?.headerCollection?.items[0] ?? [];

  useEffect(() => {
    // Check if window is defined to avoid server-side rendering errors
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 1150);

      const handleResize = () => {
        setIsMobile(window.innerWidth < 1150);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return (
    <div className={`${styles.headerContainer} ${props.className ?? ''}`}>
      <header className={styles.header} ref={headerRef}>
        <div className="container container--max">
          <div className={styles.headerWrapper} ref={headerWrapperRef}>
            <div className={styles.logoMenuWrapper}>
              <Link
                href={pageData?.logo?.imageUrl}
                title={pageData?.logo?.title}
                className={styles.headerLogo}
              >
                {props?.logo ?? 
                  <Image
                    src={pageData?.logo?.image?.url}
                    alt={pageData?.logo?.altText}
                    width={182}
                    height={35.2}
                    priority={true}
                  /> 
                ?? <Logo
                  width={182}
                  height={35.2}
                />}
              </Link>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default SiteHeaderSlim;
