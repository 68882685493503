import Link from 'next/link';
import React, { MouseEventHandler } from 'react';
import { PlayBtn, SendPlane } from '../svgs/icons';
import styles from './Button.module.scss';

export type HeightVariant = 'narrow';

type ButtonStyle = 'accent' | 'secondary-accent' | 'default' | 'link';

export type ButtonProps = {
  id?: string;
  title?: string;
  containsSubmitIcon?: boolean;
  type?: string;
  openInNewTab?: boolean;
  playIcon?: boolean;
  heightVariant?: HeightVariant;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  href?: string;
  className?: string;
  tabIndex?: number;
  isMenuButton?: boolean;
  disabled?: boolean;
  fadeUp?: boolean;
  buttonStyle?: ButtonStyle;
}

export const Button: React.FC<ButtonProps> = ({
  id,
  title = '',
  href,
  containsSubmitIcon,
  type,
  openInNewTab,
  playIcon,
  heightVariant,
  onClick,
  className,
  tabIndex,
  isMenuButton,
  disabled,
  fadeUp = !isMenuButton,
  buttonStyle
}) => {

  const buttonClassName = [
    styles.button,
    styles[heightVariant ?? ''],
    styles[buttonStyle ?? 'default'],
    isMenuButton && styles['button--isMenu'],
    buttonStyle,
    className
  ].filter(Boolean).join(' ');

  return (
    <>
      {href
        ? (
          <Link
            id={id}
            onClick={onClick}
            className={buttonClassName}
            href={href}
            type={type}
            target={openInNewTab ? '_blank' : '_self'}
            aria-label={openInNewTab ? `${title} (opens in a new tab)` : `${title}`}
            data-aos={fadeUp ? 'fade-up' : 'false'}
            tabIndex={tabIndex}
          >
            {playIcon &&
              <PlayBtn className="svg is-icon-right" width={25} height={25} />
            }
            {title}
            {containsSubmitIcon &&
              <SendPlane className="svg" width={25} height={25} />
            }
          </Link>
        )
        : (
          <button
            id={id}
            className={buttonClassName}
            onClick={onClick}
            data-aos={fadeUp ? 'fade-up' : 'false'}
            tabIndex={tabIndex}
            disabled={disabled}
          >
            {playIcon &&
              <PlayBtn className="svg is-icon-right" width={25} height={25} />
            }
            {title}
            {containsSubmitIcon &&
              <SendPlane className="svg" width={25} height={25} />
            }
          </button>
        )
      }
    </>
  );
};
