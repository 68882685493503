import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrow = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 16 9"
		{...props}
	>
		<path
			fill="#8291AC"
			d="M7.293 8.707a1 1 0 0 0 1.414 0l6.364-6.364A1 1 0 0 0 13.657.93L8 6.586 2.343.929A1 1 0 0 0 .93 2.343zM7 7v1h2V7z"
		/>
	</svg>
);
export default SvgArrow;
