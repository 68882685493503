import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsRv = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M83.3 70.21H16.42a1.25 1.25 0 0 0 0 2.5H83.3a1.25 1.25 0 0 0 0-2.5M55.42 82.22h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M82.42 82.22h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M28.42 82.22h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M20.12 58.44h5.31a5.86 5.86 0 0 0 5.82 5.21c3.01 0 5.5-2.29 5.82-5.21h25.94a5.86 5.86 0 0 0 5.82 5.21c3.01 0 5.5-2.29 5.82-5.21h9.68V47.45l-9.53-4.9-4.78-9.31h1.73s.08-.02.13-.03h3.56v-8.7L70.61 18h-40.7l-3.27 5.17h-6.27c-2.97 0-5.37 2.41-5.37 5.37v24.78c0 2.83 2.29 5.12 5.12 5.12m11.13 2.71c-1.63 0-2.99-1.17-3.29-2.71-.04-.21-.07-.42-.07-.65 0-.69.21-1.32.56-1.85.6-.9 1.63-1.5 2.8-1.5s2.19.6 2.8 1.5c.35.53.56 1.17.56 1.85 0 .22-.02.44-.07.65a3.36 3.36 0 0 1-3.29 2.71m12.69-5.36V34.25c0-.31.25-.57.57-.57h7.78c.31 0 .57.25.57.57v21.54h-8.92m21.6 2.64c-.04-.21-.07-.42-.07-.65 0-.69.21-1.32.56-1.85.6-.9 1.63-1.5 2.8-1.5s2.19.6 2.8 1.5c.35.53.56 1.17.56 1.85 0 .22-.02.44-.07.65a3.36 3.36 0 0 1-3.29 2.71c-1.63 0-2.99-1.17-3.29-2.71m2.25-24.1 4.19 8.17H61.39v-9.28h6.14c0 .38.08.76.26 1.11M17.5 28.54c0-1.58 1.29-2.87 2.87-2.87h2.6s.06.02.1.02h43.66a1.25 1.25 0 0 0 0-2.5H29.59l1.7-2.69h38.08l3.59 4.83v5.38h-2.93c-.07 0-.13.02-.2.03h-9.68c-.69 0-1.25.56-1.25 1.25v11.78c0 .69.56 1.25 1.25 1.25h13.17c.2 0 .38-.06.55-.14l7.97 4.1v6.97h-7.45c-.78-2.32-2.97-4-5.55-4s-4.77 1.68-5.55 4h-7.93V34.26c0-1.69-1.38-3.07-3.07-3.07h-7.78c-1.69 0-3.07 1.38-3.07 3.07v21.69H36.8c-.78-2.32-2.97-4-5.55-4s-4.77 1.68-5.55 4h-5.58c-1.44 0-2.62-1.18-2.62-2.62z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#003049"
			d="M25.82 41.04h11.54c.69 0 1.25-.56 1.25-1.25v-7.35c0-.69-.56-1.25-1.25-1.25H25.82c-.69 0-1.25.56-1.25 1.25v7.35c0 .69.56 1.25 1.25 1.25m1.25-7.35h9.04v4.85h-9.04z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsRv;
