import DOMPurify from 'isomorphic-dompurify';
import { CSSProperties } from 'react';
import styles from './RemoteSvg.module.scss';

interface RemoteSvgProps {
	url: URL | string;
	width?: number;
	height?: number;
}

export const RemoteSvg = async ({ url, width, height }: RemoteSvgProps) => (
	<span
		className={styles['remote-svg']}
		style={
			{
				display: 'contents',
				'--bw-svg-width': width ? `${width}px` : undefined,
				'--bw-svg-height': height ? `${height}px` : undefined,
			} as CSSProperties
		}
		dangerouslySetInnerHTML={{
			__html: DOMPurify.sanitize(
				await fetch(url)
					.then((r) => r.text())
					.catch(() => '')
			),
		}}
	></span>
);
