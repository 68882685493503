import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLoader = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		preserveAspectRatio="xMidYMid"
		style={{
			margin: 'auto',
			background: '0 0',
			display: 'block',
			shapeRendering: 'auto',
		}}
		viewBox="0 0 100 100"
		{...props}
	>
		<circle
			cx={50}
			cy={50}
			r={27}
			fill="none"
			stroke="#f0ff00"
			strokeDasharray="127.23450247038662 44.411500823462205"
			strokeWidth={3}
		>
			<animateTransform
				attributeName="transform"
				dur="0.5952380952380952s"
				keyTimes="0;1"
				repeatCount="indefinite"
				type="rotate"
				values="0 50 50;360 50 50"
			/>
		</circle>
	</svg>
);
export default SvgLoader;
