import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 51 51"
		{...props}
	>
		<circle
			cx={25.5}
			cy={25.5}
			r={25.5}
			fill="#fff"
			style={{
				fill: '#fff',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#000"
			d="M35.86 24.164a1.519 1.519 0 0 1 0 2.672l-14.344 8.017c-1.063.594-2.391-.148-2.391-1.336V17.483c0-1.188 1.328-1.93 2.39-1.337z"
			style={{
				fill: '#000',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgPlay;
