"use client";
import { Outbox, Task, UploadFile } from '@bw/elements/icons';
import { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { FieldError, UseFormReturn } from 'react-hook-form';
import { FORM_ERROR } from '../../../constants';
import { ConditionalFieldError } from '../errors';
import styles from './formFileInput.module.scss';

// For more info on this implementation, 
// https://betterprogramming.pub/how-to-implement-files-drag-and-drop-in-react-22cf42b7a7ef
// https://dreamix.eu/insights/uploading-files-with-react-hook-form/
// https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL_static

interface FileInputProps {
  context: UseFormReturn<any>,
  id: string,
  required?: boolean,
  className?: string,
  formError?: string,
  children?: any,
}

export const FormFileInput = ({
  context: { register, formState, setError, setValue, getValues },
  id,
  required,
  className = styles.fileInput,
  formError = FORM_ERROR.REQUIRED,
  children,
  ...inputProps
}: FileInputProps & InputHTMLAttributes<HTMLInputElement>,
) => {
  const { ref: registerRef } = register(id);
  const [isDragging, setIsDragging] = useState(false);
  const labelRef = useRef<HTMLLabelElement>(null);
  const attachedFile: File = getValues(id);

  useEffect(() => {
    labelRef.current?.addEventListener('dragover', handleDragOver);
    labelRef.current?.addEventListener('drop', handleDrop);
    labelRef.current?.addEventListener('dragenter', handleDragEnter);
    labelRef.current?.addEventListener('dragleave', handleDragLeave);

    return () => {
      labelRef.current?.removeEventListener('dragover', handleDragOver);
      labelRef.current?.removeEventListener('drop', handleDrop);
      labelRef.current?.removeEventListener('dragenter', handleDragEnter);
      labelRef.current?.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  const getFile = async (files) => {
    if (!files) {
      setError(id, { message: 'Something went wrong, please try again.' });
      return;
    }

    if (files.length !== 1) {
      setError(id, { message: 'Please upload only a single file.' });
      return;
    }

    const file = files[0];

    if (file.size > 500000) {
      setError(id, { message: 'Each file must be under 5 MB.' });
      return;
    }

    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        file.encoded = reader.result;

        setValue(id, file, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      },
      false,
    );
    await reader.readAsDataURL(file);
  }

  const handleChange = (e) => {
    getFile(e?.target?.files);
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    getFile(e?.dataTransfer?.files);
    setIsDragging(false);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const responsiveClassName = isDragging ? styles.dragging : attachedFile?.size ? styles.filled : styles.notFilled;
  const responsiveIcon = isDragging ? <Outbox height={40} width={40} /> 
    : attachedFile?.size ? <Task height={40} width={40} /> 
    : <UploadFile height={40} width={40} />;

  const responsiveLabel = isDragging ? 'Drop document to upload' 
    : attachedFile?.size ? (attachedFile.name) 
    : (<div>
      <u>Upload</u> your file here<br />
      5MB max
    </div>);

  return (<>
    {children}
    <div className={`${className} ${responsiveClassName}`}>
      <input
        ref={(e) => { registerRef(e) }}
        id={id}
        type="file"
        required={required && !attachedFile?.size}
        onChange={handleChange}
        {...inputProps}
      />
      <label htmlFor={id} ref={labelRef}>
        <div>
          <span className={styles.icon}>
            {responsiveIcon}
          </span>
        </div>
        {responsiveLabel}
      </label>
    </div>
    <ConditionalFieldError error={formState.errors?.[id] as FieldError} data-aos="fade-up" />
  </>)
}