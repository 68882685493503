import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsOther = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M74.02 49.14H51.24V26.36a1.25 1.25 0 0 0-2.5 0v22.78H25.96a1.25 1.25 0 0 0 0 2.5h22.78v22.78a1.25 1.25 0 0 0 2.5 0V51.64h22.78a1.25 1.25 0 0 0 0-2.5"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#003049"
			d="M73.98 37.73h-11.2v-11.2c0-6.91-5.62-12.52-12.52-12.52s-12.52 5.62-12.52 12.52v11.2h-11.2c-6.91 0-12.52 5.62-12.52 12.52s5.62 12.52 12.52 12.52h11.2v11.2c0 6.91 5.62 12.52 12.52 12.52s12.52-5.62 12.52-12.52v-11.2h11.2c6.91 0 12.52-5.62 12.52-12.52s-5.62-12.52-12.52-12.52m0 22.55h-13.7v13.7c0 5.53-4.5 10.02-10.02 10.02s-10.02-4.5-10.02-10.02v-13.7h-13.7c-5.53 0-10.02-4.5-10.02-10.02s4.5-10.02 10.02-10.02h13.7v-13.7c0-5.53 4.5-10.02 10.02-10.02s10.02 4.5 10.02 10.02v13.7h13.7c5.53 0 10.02 4.5 10.02 10.02s-4.5 10.02-10.02 10.02"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsOther;
