'use client';
import { LoggerEvents, logger } from '@bw/core';
import {
	BackIcon,
	CloseIcon,
	InsuranceIconsAuto,
	InsuranceIconsBusiness,
	InsuranceIconsCondo,
	InsuranceIconsFlood,
	InsuranceIconsHome,
	InsuranceIconsHomeWarranty,
	InsuranceIconsLife,
	InsuranceIconsMotorcycle,
	InsuranceIconsOther,
	InsuranceIconsPersonal,
	InsuranceIconsPet,
	InsuranceIconsRental,
	InsuranceIconsRv,
	InsuranceIconsToys,
	InsuranceIconsUmbrella,
	Logo,
	LogoBlue,
	Press
} from '@bw/elements/icons';
import { Lead } from '@bw/external-apis';
import { getCookie } from 'cookies-next';
import DOMPurify from 'isomorphic-dompurify';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ChangeEvent, SVGProps, useEffect, useRef, useState, useTransition } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FORM_ERROR } from '../../constants';
import { gtmEvent } from '../GTM/gtmEvent';
import LoaderCss from '../loader-css/LoaderCss';
import { NavigationEvents } from '../navigation-events/navigation-events';
import styles from './FormStepper.module.scss';
import { useAppContext } from './StepperProvider';

interface AgencyDetails {
	id: number;
	marketingName: string;
	agencyRelativeUrl: string;
	isHouseAccount: boolean;
}
type WindowtHeightProps = Record<string, string>;

export function FormStepper() {
	const [currentStep, setCurrentStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [agencyDetails, setAgencyDetails] = useState<AgencyDetails | ''>('');
	const { showFormStepper, setShowFormStepper } = useAppContext();
	const stepperRef = useRef<HTMLDivElement>(null);

	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		origin: '',
		address1: '',
		address2: '',
		city: '',
		state: '',
		postalCode: '',
		leadInsuranceTypeId: 0,
	});
	const path = usePathname();
	const [windowHeight, setWindowHeight] = useState(1000);
	const searchParams = useSearchParams()
	const router = useRouter()
	const [isPending, startTransition] = useTransition()
	const [isDirectNav, setIsDirectNav] = useState(false)
	const formQueryParam = 'start-a-quote'
	const { executeRecaptcha } = useGoogleReCaptcha();

	const getCaptchaToken = async (action: string | undefined): Promise<string> => {
		const message = 'executeRecaptcha not available on form submit, possible react script loading error.';
		if (!executeRecaptcha) {
			logger.error(LoggerEvents.GeneralErrorWebApp, { message });
			return 'no recaptcha';
		}
		try {
			const token = await executeRecaptcha(action);
			return token;
		} catch (error) {
			logger.error(LoggerEvents.GeneralErrorWebApp, { message, error });
		}
		return 'no recaptcha';
	};

	// when closing form stepper and search params are present 1) remove params 2) close form
	useEffect(() => {
		if (isDirectNav && !searchParams.has(formQueryParam) && showFormStepper) {
			setShowFormStepper(!showFormStepper)
			localStorage.removeItem('postalCode')
			localStorage.removeItem('leadInsuranceTypeId')
		}

		if (isPending) {
			setIsDirectNav(true)
		}
	}, [isPending, isDirectNav, setIsDirectNav, searchParams, showFormStepper, setShowFormStepper])

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setWindowHeight(window.innerHeight);
			const handleHeightChange = () => {
				setWindowHeight(window.innerHeight);
			};
			window.addEventListener('resize', handleHeightChange);
			return () => {
				window.removeEventListener('resize', handleHeightChange);
			};
		}
	}, []);
	const windowHeightStyle: WindowtHeightProps = {
		'--window-height': `${windowHeight}px`,
	};

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			handleFocusWithinModal(event);
		};

		if (stepperRef?.current) {
			stepperRef?.current?.addEventListener('keydown', handleKeyDown);

			return () => {
				stepperRef?.current?.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [showFormStepper]);

	const handleFocusWithinModal = (e: KeyboardEvent) => {
		const modal = stepperRef.current;
		if (!modal) {
			return;
		}

		const focusableElements = modal.querySelectorAll(
			'a, button, [tabindex]:not([tabindex="-1"])'
		);

		const focusableElementsArray =
			Array.prototype.slice.call(focusableElements);
		const firstFocusableElement = focusableElementsArray[0];
		const lastFocusableElement =
			focusableElementsArray[focusableElementsArray.length - 1];

		if (e.key === 'Tab' || e.keyCode === 9) {
			if (e.shiftKey) {
				if (document.activeElement === firstFocusableElement) {
					e.preventDefault();
					lastFocusableElement.focus();
				}
			} else {
				if (document.activeElement === lastFocusableElement) {
					e.preventDefault();
					firstFocusableElement.focus();
				}
			}
		}
	};

	const getLeadSourceId = (urlPath: string): string => {
		let leadSourceId = getCookie('leadSourceId');

		if (!leadSourceId) {
			if (/\/agencies\/.+/.test(urlPath)) {
				leadSourceId = '0';
			} else {
				leadSourceId = '1';
			}
		}
		return leadSourceId;
	};

	useEffect(() => {
		document.body.classList.add('disableScroll');

		const storedLeadInsuranceTypeId = localStorage.getItem(
			'leadInsuranceTypeId'
		);

		if (storedLeadInsuranceTypeId) {
			setRadio(storedLeadInsuranceTypeId);
			setValue('leadInsuranceTypeId', parseInt(storedLeadInsuranceTypeId, 10));
			setFormData((prevFormData) => ({
				...prevFormData,
				leadInsuranceTypeId: parseInt(storedLeadInsuranceTypeId, 10),
			}));
		}

		const storedPostalCode = localStorage.getItem('postalCode');
		if (storedPostalCode) {
			setValue('postalCode', storedPostalCode);
			setFormData((prevFormData) => ({
				...prevFormData,
				postalCode: storedPostalCode,
			}));
		}

		const storedStoreNumber = localStorage.getItem('storeNumber');
		if (storedStoreNumber) {
			setValue('storeNumber', storedStoreNumber);
			setFormData((prevFormData) => ({
				...prevFormData,
				storeNumber: storedStoreNumber,
			}));
		}

		const leadSourceId = getLeadSourceId(path);
		setFormData((prevFormData) => ({
			...prevFormData,
			leadSourceId: leadSourceId,
		}));

		setFormData((prevFormData) => ({
			...prevFormData,
		}));

		return () => {
			document.body.classList.remove('disableScroll');
		};
	}, []);

	const handleContinue = () => {
		setCurrentStep((prevStep) => prevStep + 1);
	};

	const handleBack = () => {
		setCurrentStep((prevStep) => prevStep - 1);
	};
	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues,
		register,
		setError,
		trigger,
		setValue,
	} = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			origin: '',
			address1: '',
			address2: '',
			city: '',
			state: '',
			postalCode: '',
			leadInsuranceTypeId: 0,
			storeNumber: null as string | null,
		},
	});
	const [radio, setRadio] = useState<string | undefined>('');
	const [radioError, setRadioError] = useState(false);

	const changeRadio = (e: any) => {
		let id = e.target.value;
		setRadio(id);
		setFormData((prevFormData) => ({
			...prevFormData,
			leadInsuranceTypeId: id,
		}));
		setValue('leadInsuranceTypeId', id);
		setRadioError(false);
	};

	const insuranceTypes = [
		{ id: 'home', value: '1', label: 'Home' },
		{ id: 'flood', value: '2', label: 'Flood' },
		{ id: 'condo', value: '3', label: 'Condo' },
		{ id: 'rental', value: '4', label: 'Renters' },
		{ id: 'personalArticles', value: '5', label: 'Personal Articles' },
		{ id: 'auto', value: '6', label: 'Auto' },
		{ id: 'motorcycle', value: '7', label: 'Motorcycle' },
		{ id: 'rv', value: '8', label: 'RV' },
		{ id: 'boat', value: '9', label: 'Boat' },
		{ id: 'business', value: '10', label: 'Business' },
		{ id: 'life', value: '11', label: 'Life' },
		{ id: 'umbrella', value: '12', label: 'Umbrella' },
		{ id: 'pets', value: '15', label: 'Pets' },
		{ id: 'other', value: '9999', label: 'Other' },
	] as const;
	const onSubmit = (data) => {
		let temp = { ...formData };
		for (const key in data) {
			if (Object.prototype.hasOwnProperty.call(data, key)) {
				temp[key] = data[key];
			}
		}
		setFormData(temp);

		if (currentStep === 3) {
			if (formData.leadInsuranceTypeId == 0) {
				setRadioError(true);
				setError('leadInsuranceTypeId', {
					types: {
						required: true,
					},
				});

				trigger('leadInsuranceTypeId');

				return;
			}
		}
		if (currentStep === 6) {

			const sendQuote = async (lead: Lead) => {
				const gaFormData = new FormData(document.querySelector('#stepper-ga-form') as HTMLFormElement);
				lead.googleClickId = gaFormData.get('gclid') as string;
				lead.googleClientId = gaFormData.get('ga_id') as string;
				lead.captchaToken = await getCaptchaToken('startAQuote');
				setLoading(true);
				try {
					const response = await fetch('/api/start-a-quote', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(lead),
					});
					const data: AgencyDetails | '' = await response.json();
					setAgencyDetails(data);
					setLoading(false);
					if (!response.ok) {
						toast.error('Please enter valid zipcode', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						throw new Error(
							response.statusText + ' ' + (await response.text())
						);
					} else {
						handleContinue();
						gtmEvent('form_success', { formName: 'start-a-quote' });
					}
				} catch (err) {
					console.error(err);
				}
			};
			// Sanitize user input
			const sanitizedData = Object.keys(temp).reduce((acc, key) => {
				acc[key] = DOMPurify.sanitize(temp[key]);
				return acc;
			}, {} as Lead);

			sendQuote(sanitizedData);
			localStorage.removeItem('postalCode');
			localStorage.removeItem('leadInsuranceTypeId');
			localStorage.removeItem('storeNumber');
		}
		if (currentStep !== 6 && currentStep !== steps.length) {
			handleContinue();
		}
	};

	const handleOnInput = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.value = e.target.value.replace(/[^0-9]/g, '')
	}

	const steps = [
		{
			stepTag: 'stepOne',
			body: (
				<div className="formstepper__body">
					<h1 className="h1">
						Welcome to <b>Brightway</b>
					</h1>
					<p>
						Ready to get started? We just need to learn a bit about you. Cool?
					</p>
					<div className="formstepper__footer">
						<button
							title="Submit"
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(onSubmit)();
							}}
							autoFocus={true}
							className="l_continue"
							type="submit"
						>
							Continue
							<Press width={66} height={32} />
						</button>
					</div>
				</div>
			),
		},
		{
			stepTag: 'stepTwo',

			body: (
				<div className="formstepper__body">
					<h1 className="h1">What’s your name?</h1>
					<form
						action=""
						className="formstepper__form"
						id="stepper-second-step"
					>
						<Controller
							control={control}
							rules={{
								required: {
									value: true,
									message: FORM_ERROR.REQUIRED,
								},
								pattern: {
									value: /^(?!^\s+$)[\w\s'-]{1,50}$/,
									message: FORM_ERROR.FNAME,
								},
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<div className="form__group w_50">
									<label htmlFor="firstName">First Name</label>
									<input
										placeholder="First Name"
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										id="firstName"
										autoFocus={true}
										data-testid="first-name"
									/>
									{errors.firstName && (
										<span data-testid="first-name-error" className="error">
											{errors.firstName.message}
										</span>
									)}
								</div>
							)}
							name="firstName"
						/>

						<Controller
							control={control}
							rules={{
								required: {
									value: true,
									message: FORM_ERROR.REQUIRED,
								},
								pattern: {
									value: /^(?!^\s+$)[\w\s'-]{1,50}$/,
									message: FORM_ERROR.LNAME,
								},
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<div className="form__group w_50">
									<label htmlFor="lastName">Last Name</label>
									<input
										placeholder="Last Name"
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										id="lastName"
										data-testid="last-name"
									/>
									{errors.lastName && (
										<p data-testid="last-name-error" className="error">
											{errors.lastName.message}
										</p>
									)}
								</div>
							)}
							name="lastName"
						/>

						<div className="formstepper__footer">
							<button
								title="Submit"
								onClick={(e) => {
									e.preventDefault();
									handleSubmit(onSubmit)();
								}}
								className="l_continue"
								type="submit"
							>
								Continue
								<Press width={66} height={32} />
							</button>
						</div>
					</form>
				</div>
			),
		},
		{
			stepTag: 'StepThree',
			body: (
				<div className="formstepper__body">
					<h1 className="h1 h1_name">
						Nice to meet you,
						<br />{' '}
						<b>
							<i>{formData.firstName}!</i>
						</b>
					</h1>
					<div className="formstepper__footer">
						<button
							title="Submit"
							onClick={(e) => {
								e.preventDefault();
								handleSubmit(onSubmit)();
							}}
							autoFocus={true}
							className="l_continue"
							type="submit"
						>
							Continue
							<Press width={66} height={32} />
						</button>
					</div>
				</div>
			),
			footer: <></>,
		},
		{
			stepTag: 'StepFour',

			body: (
				<div className="formstepper__body__StepEight">
					<div className="formstepper__body select_form">
						<h2 className="h1">
							What kind of insurance
							<br /> are you interested in?
						</h2>
						<ul className="icon__card">
							{insuranceTypes.map((type) => (
								<li key={type.id}>
									<div
										className="icon__card-item"
										role="radio"
										aria-checked="true"
									>
										<button
											value={type.value}
											type="button"
											onClick={(e) => {
												changeRadio(e);
												document.getElementById('radio-submit-button')?.focus();
											}}
										>
											<input
												id={type.id}
												role="radio"
												type="radio"
												value={type.value}
												checked={radio === type.value}
												{...register('leadInsuranceTypeId')}
												readOnly={true}
											/>

											<label htmlFor={type.id}>
												<figure>
													<InsuranceIcon insuranceType={type.value}
														className="svg"
													/>
												</figure>
												<p>{type.label}</p>
											</label>
										</button>
									</div>
								</li>
							))}
						</ul>
						{radioError && (
							<p data-testid="insurance-type-error" className="checkbox_error">
								This field is required
							</p>
						)}
						<div className="formstepper__footer">
							<button
								title="Submit"
								onClick={(e) => {
									e.preventDefault();
									handleSubmit(onSubmit)();
								}}
								className="l_continue"
								type="submit"
							>
								Continue
								<Press width={66} height={32} />
							</button>
						</div>
					</div>
				</div>
			),
		},
		{
			stepTag: 'StepFive',

			body: (
				<div className="formstepper__body">
					<h2 className="h1">How can we get in touch?</h2>
					<form action="" className="formstepper__form" id="stepper-fifth-step">
						<Controller
							control={control}
							rules={{
								required: {
									value: true,
									message: FORM_ERROR.REQUIRED,
								},
								pattern: {
									value:
										/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
									message: FORM_ERROR.EMAIL,
								},
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<div className="form__group w_50">
									<label htmlFor="email">Email</label>
									<input
										placeholder="name@email.xyz"
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										id="email"
										autoFocus={true}
										data-testid="email"
									/>
									{errors.email && (
										<p data-testid="email-error" className="error">
											{errors.email.message}
										</p>
									)}
								</div>
							)}
							name="email"
						/>

						<Controller
							control={control}
							rules={{
								required: {
									value: true,
									message: FORM_ERROR.REQUIRED,
								},
								pattern: {
									value:
										/^([0-9]{0,3})[-.●]?([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/,
									message: FORM_ERROR.PHONENUMBER,
								},
							}}
							render={({ field: { onChange, onBlur, value } }) => (
								<div className="form__group w_50">
									<label htmlFor="phone">Phone Number</label>
									<input
										onBlur={onBlur}
										onChange={onChange}
										value={value}
										id="phone"
										data-testid="phone"
									/>
									{errors.phone && (
										<p data-testid="phone-error" className="error">
											{errors.phone.message}
										</p>
									)}
								</div>
							)}
							name="phone"
						/>

						<div className="formstepper__footer">
							<button
								title="Submit"
								onClick={(e) => {
									e.preventDefault();
									handleSubmit(onSubmit)();
								}}
								className="l_continue"
								type="submit"
							>
								Continue
								<Press width={66} height={32} />
							</button>
						</div>
					</form>
				</div>
			),
		},
		{
			stepTag: 'StepSix',

			body: (
				<div className="formstepper__body__StepSeven">
					<div className="formstepper__body">
						<h2 className="h1">What is your address?</h2>
						<form
							action=""
							className="formstepper__form"
							id="stepper-sixth-step"
						>
							<Controller
								control={control}
								rules={{
									required: {
										value: true,
										message: FORM_ERROR.REQUIRED,
									},
									pattern: {
										value: /^(?!\s*$).{1,100}$/,
										message: 'Enter valid address',
									},
								}}
								render={({ field: { onChange, onBlur } }) => (
									<div className="form__group">
										<label htmlFor="address1">Insured Property Address</label>
										<input
											onBlur={onBlur}
											onChange={onChange}
											value={getValues('address1')}
											id="address1"
											autoFocus={true}
											data-testid="address-1"
										/>
										{errors.address1 && (
											<p data-testid="address-1-error" className="error">
												{errors.address1.message}
											</p>
										)}
									</div>
								)}
								name="address1"
							/>
							<Controller
								control={control}
								rules={{
									pattern: {
										value: /^(?!\s*$).{1,100}$/,
										message: 'Enter valid address',
									},
								}}
								render={({ field: { onChange, onBlur, value } }) => (
									<div className="form__group">
										<label htmlFor="address2">Apt / Suite #</label>
										<input
											placeholder=""
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											id="address2"
											data-testid="address-2"
										/>
										{errors.address2 && (
											<p data-testid="address-2-error" className="error">
												{errors.address2.message}
											</p>
										)}
									</div>
								)}
								name="address2"
							/>
							<Controller
								control={control}
								rules={{
									required: {
										value: true,
										message: FORM_ERROR.REQUIRED,
									},
									pattern: {
										value: /^(?!\s*$).{1,20}$/,
										message: 'Enter valid city',
									},
								}}
								render={({ field: { onChange, onBlur, value } }) => (
									<div className="form__group">
										<label htmlFor="city">City</label>
										<input
											placeholder=""
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											id="city"
											data-testid="city"
										/>
										{errors.city && (
											<p data-testid="city-error" className="error">
												{errors.city.message}
											</p>
										)}
									</div>
								)}
								name="city"
							/>
							<Controller
								control={control}
								rules={{
									required: {
										value: true,
										message: FORM_ERROR.REQUIRED,
									},
								}}
								render={({ field: { onChange, onBlur, value } }) => (
									<div className="form__group w_50">
										<label htmlFor="state">State</label>
										<select
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											id="state"
											data-testid="state"
										>
											<option value="" disabled hidden>
												Select State
											</option>
											<option value="AL">Alabama</option>
											<option value="AK">Alaska</option>
											<option value="AZ">Arizona</option>
											<option value="AR">Arkansas</option>
											<option value="CA">California</option>
											<option value="CO">Colorado</option>
											<option value="CT">Connecticut</option>
											<option value="DE">Delaware</option>
											<option value="DC">District of Columbia</option>
											<option value="FL">Florida</option>
											<option value="GA">Georgia</option>
											<option value="HI">Hawaii</option>
											<option value="ID">Idaho</option>
											<option value="IL">Illinois</option>
											<option value="IN">Indiana</option>
											<option value="IA">Iowa</option>
											<option value="KS">Kansas</option>
											<option value="KY">Kentucky</option>
											<option value="LA">Louisiana</option>
											<option value="ME">Maine</option>
											<option value="MD">Maryland</option>
											<option value="MA">Massachusetts</option>
											<option value="MI">Michigan</option>
											<option value="MN">Minnesota</option>
											<option value="MS">Mississippi</option>
											<option value="MO">Missouri</option>
											<option value="MT">Montana</option>
											<option value="NE">Nebraska</option>
											<option value="NV">Nevada</option>
											<option value="NH">New Hampshire</option>
											<option value="NJ">New Jersey</option>
											<option value="NM">New Mexico</option>
											<option value="NY">New York</option>
											<option value="NC">North Carolina</option>
											<option value="ND">North Dakota</option>
											<option value="OH">Ohio</option>
											<option value="OK">Oklahoma</option>
											<option value="OR">Oregon</option>
											<option value="PA">Pennsylvania</option>
											<option value="RI">Rhode Island</option>
											<option value="SC">South Carolina</option>
											<option value="SD">South Dakota</option>
											<option value="TN">Tennessee</option>
											<option value="TX">Texas</option>
											<option value="UT">Utah</option>
											<option value="VT">Vermont</option>
											<option value="VA">Virginia</option>
											<option value="WA">Washington</option>
											<option value="WV">West Virginia</option>
											<option value="WI">Wisconsin</option>
											<option value="WY">Wyoming</option>
										</select>
										{errors.state && (
											<p data-testid="state-error" className="error">
												{errors.state.message}
											</p>
										)}
									</div>
								)}
								name="state"
							/>
							<Controller
								control={control}
								rules={{
									required: {
										value: true,
										message: FORM_ERROR.REQUIRED,
									},
									pattern: {
										value: /^(?!00000)\d{5}$/,
										message: 'Enter valid zipcode',
									},
								}}
								render={({ field: { onChange, onBlur, value } }) => (
									<div className="form__group w_50">
										<label htmlFor="postalCode">Zip Code</label>
										<input
											placeholder=""
											onBlur={onBlur}
											onChange={onChange}
											value={value}
											id="postalCode"
											data-testid="postal-code"
											type="text"
											inputMode='numeric'
											onInput={handleOnInput}
											minLength={5}
											maxLength={5}
										/>
										{errors.postalCode && (
											<p data-testid="postal-code-error" className="error">
												{errors.postalCode.message}
											</p>
										)}
									</div>
								)}
								name="postalCode"
							/>
							<div className="formstepper__footer">
								<button
									title="Continue"
									onClick={(e) => {
										e.preventDefault();
										handleSubmit(onSubmit)();
									}}
									className="l_continue"
									type="submit"
								>
									Continue
									<Press width={66} height={32} />
								</button>
							</div>
						</form>
					</div>
				</div>
			),
		},
		{
			stepTag: 'StepSeven',

			body: (
				<div className="formstepper__body">
					<h2 className="h1">
						How did you hear about
						<br /> us?
					</h2>
					<form action="" className="formstepper__form" id="stepper-ninth-step">
						<Controller
							control={control}
							rules={{
								pattern: {
									value: /^.{1,225}$/,
									message: 'Your message is too long, please shorten it.',
								},
							}}
							render={({ field: { onChange, onBlur } }) => (
								<div className="form__group">
									<label htmlFor="origin">Let Us Know</label>
									<input
										placeholder=""
										onBlur={onBlur}
										onChange={onChange}
										value={getValues('origin')}
										id="origin"
										data-testid="origin"
										autoFocus={true}
									/>
									{errors.origin && (
										<p data-testid="origin-error" className="error">
											{errors.origin.message}
										</p>
									)}
								</div>
							)}
							name="origin"
						/>
						<div className="formstepper__footer">
							<button
								title="Submit"
								id="radio-submit-button"
								onClick={(e) => {
									e.preventDefault();
									handleSubmit(onSubmit)();
								}}
								className="l_continue"
								type="submit"
							>
								Submit for a quote
								<Press width={66} height={32} />
							</button>
						</div>
					</form>
				</div>
			),
		},
		{
			stepTag: 'StepEight',
			body: (
				<>
					<div className="formstepper__body_End_Wrapper">
						<div className="formstepper__body">
							<h2 className="h1 h1_name">
								Thank you. <br /> You’re all set, {formData.firstName}.
							</h2>
							{typeof agencyDetails === 'object' &&
								'marketingName' in agencyDetails &&
								'agencyRelativeUrl' in agencyDetails &&
								!agencyDetails.isHouseAccount ? (
								<>
									<p>
										{agencyDetails.marketingName} has your info and will reach out to you
										in the next 24 hours.
									</p>
									<a
										href={agencyDetails.agencyRelativeUrl}
										className="l-btn"
										title={`Check out ${agencyDetails.marketingName}'s site`}
									>
										Check out {agencyDetails.marketingName}&apos;s site
									</a>
								</>
							) : (
								<>
									<p>We will reach out to you in the next 24 hours.</p>
									<Link
										href={'/'}
										className="l-btn"
										onClick={() => {
											setShowFormStepper(!showFormStepper);
										}}
									>
										Back to Home
									</Link>
								</>
							)}
						</div>
					</div>
					<div className="formstepper__footer">
						<h6>Get ready for a brighter way to do insurance</h6>
					</div>
				</>
			),
		},
	];

	return (
		<div className={styles['formstepper']} ref={stepperRef}>
			<form id='stepper-ga-form'>
				<input type='hidden' name='gclid' />
				<input type='hidden' name='ga_id' />
			</form>

			<div
				className={`formstepper__bg ${currentStep === 7 ? 'formstepper__bg theme__yellow' : ''
					}`}
			>
				<div className="container">
					<div className="formstepper__wrapper" style={windowHeightStyle}>
						<div className="formstepper__header ">
							<Link
								href="/"
								className="l_logo"
								onClick={() => {
									setShowFormStepper(!showFormStepper);
								}}
							>
								{currentStep === 7
									? <LogoBlue />
									: <Logo />
								}
							</Link>
							{!loading && (
								<div className="action_buttons">
									{![0, 7].includes(currentStep) && (
										<button
											className="l_back"
											onClick={() => {
												handleBack();
											}}
										>
											<BackIcon className="svg" width={24} height={24} />
											Back
										</button>
									)}
									<button
										className="l_close"
										onClick={() => {
											if (searchParams.has(formQueryParam)) {
												startTransition(() => {
													router.push(path)
												})
											} else {
												setShowFormStepper(!showFormStepper)
												localStorage.removeItem('postalCode')
												localStorage.removeItem('leadInsuranceTypeId')
											}
										}}
									>
										<CloseIcon className="svg" width={24} height={24} />
										Close
									</button>
								</div>
							)}
						</div>
						{loading ? (
							<div className="formstepper__body">
								<LoaderCss />
							</div>
						) : (
							<div>{steps[currentStep].body}</div>
						)}
					</div>
				</div>
			</div>
			<NavigationEvents />
		</div>
	);
}

const iconMap = {
	'1': InsuranceIconsHome,
	'2': InsuranceIconsFlood,
	'3': InsuranceIconsCondo,
	'4': InsuranceIconsRental,
	'5': InsuranceIconsPersonal,
	'6': InsuranceIconsAuto,
	'7': InsuranceIconsMotorcycle,
	'8': InsuranceIconsRv,
	'9': InsuranceIconsToys,
	'10': InsuranceIconsBusiness,
	'11': InsuranceIconsLife,
	'12': InsuranceIconsUmbrella,
	'13': InsuranceIconsHomeWarranty,
	'14': InsuranceIconsPet,
	'15': InsuranceIconsOther,
	'9999': InsuranceIconsOther,
};

const InsuranceIcon = <T extends {}>({ insuranceType, ...props }: {
	insuranceType: keyof typeof iconMap
} & SVGProps<SVGSVGElement>) => {

	const Icon = iconMap[insuranceType] ?? InsuranceIconsOther;
	return <Icon {...props} />;
}

export default FormStepper;
