import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsMotorcycle = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M83.14 70.23H16.25a1.25 1.25 0 0 0 0 2.5h66.89a1.25 1.25 0 0 0 0-2.5M28.25 82.24h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M55.25 82.24h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M82.25 82.24h-12a1.25 1.25 0 0 0 0 2.5h12a1.25 1.25 0 0 0 0-2.5M28.87 41.7c-5.8 0-10.52 4.72-10.52 10.52s4.72 10.52 10.52 10.52 10.52-4.72 10.52-10.52S34.67 41.7 28.87 41.7m0 18.55c-4.42 0-8.02-3.6-8.02-8.02s3.6-8.02 8.02-8.02 8.02 3.6 8.02 8.02-3.6 8.02-8.02 8.02"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#003049"
			d="M20.47 43.65c2.26-2.21 5.24-3.43 8.4-3.43 6.62 0 12 5.38 12 12 0 .69.56 1.25 1.25 1.25h13.51c.69 0 1.25-.56 1.25-1.25 0-4.27 2.25-8.02 5.62-10.15l.65 1.33c-2.88 1.88-4.8 5.13-4.8 8.82 0 5.8 4.72 10.52 10.52 10.52s10.52-4.72 10.52-10.52S74.67 41.7 68.87 41.7c-1.22 0-2.39.22-3.48.6l-.65-1.33c1.29-.48 2.68-.75 4.13-.75 2.18 0 4.31.59 6.17 1.71.59.36 1.36.17 1.72-.43.36-.59.16-1.36-.43-1.72a14.5 14.5 0 0 0-7.46-2.06c-1.85 0-3.62.35-5.24.99l-1.67-3.4c.77.22 1.61.35 2.5.35.69 0 1.25-.56 1.25-1.25v-7.9c0-.69-.56-1.25-1.25-1.25-2.67 0-4.95 1.1-6.09 2.74l-1.62-3.3c-.21-.43-.65-.7-1.12-.7H49.7a1.25 1.25 0 0 0 0 2.5h5.15l1.13 2.31h-7.57c-2.72 0-5.09 1.45-6.42 3.6H25.66a5.27 5.27 0 0 0-5.27 5.27v1.11c0 .52.32.96.77 1.15-.87.55-1.69 1.18-2.45 1.92-.49.48-.5 1.27-.02 1.77.48.49 1.27.5 1.77.02zm48.4.54c4.42 0 8.02 3.6 8.02 8.02s-3.6 8.02-8.02 8.02-8.02-3.6-8.02-8.02c0-2.71 1.35-5.1 3.41-6.55l3.49 7.11c.22.44.66.7 1.12.7.19 0 .37-.04.55-.13.62-.3.88-1.05.57-1.67l-3.49-7.11c.75-.23 1.55-.36 2.37-.36zm-5.66-16.31v5.16c-1.79-.34-3.14-1.38-3.14-2.58s1.34-2.24 3.14-2.58m-6.16 15.98c-.05 0-.1-.03-.16-.03H46.9a1.25 1.25 0 0 0 0 2.5h8.74c-.64 1.44-1.07 3-1.21 4.64H43.32c-.39-4.58-2.93-8.55-6.59-10.93h24.3c-1.56 1.01-2.9 2.31-3.97 3.82zm-8.64-12.55h8.8l3.06 6.24h-16.9v-1.19c0-2.78 2.26-5.04 5.04-5.04zm-22.74 3.6h15.34c-.09.47-.14.95-.14 1.44v1.19H22.9a2.77 2.77 0 0 1 2.77-2.63"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsMotorcycle;
