'use client';
import React from 'react';
import { useAppContext } from '../form-stepper/StepperProvider';
import Button, { ButtonProps } from '../utility/button/button';

type StepperButtonProps = Omit<ButtonProps, 'backgroundColor' | 'borderColor' | 'textColor'> & {
	storeNumber?: string;
}

export const StepperButton: React.FC<StepperButtonProps> = ({ storeNumber, title, paletteVariant, ...buttonProps }) => {
	const { showFormStepper, setShowFormStepper } = useAppContext();

	const onClick = () => {
		if (storeNumber) {
			localStorage.setItem('storeNumber', storeNumber);
		}
		setShowFormStepper(!showFormStepper);
	};

	return <Button
		title={title || 'Start a quote'}
		onClick={onClick}		
		paletteVariant={paletteVariant || 'primary'}
		{...buttonProps}
	/>
};

export default StepperButton;
