import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuote = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 79 64"
		{...props}
	>
		<path
			fill="#F0FF00"
			d="M9.971 64q-3.479 0-5.333-.232a23 23 0 0 1-3.479-.696V48.465q4.87 0 5.798-2.551.927-2.783.927-4.406v-4.87H5.565q-2.319 0-3.942-1.39Q0 33.854 0 31.072V6.029Q0-.001 5.565 0h24.812q3.014 0 3.942 1.855 1.16 1.855 1.16 4.174v36.406q0 9.275-4.175 13.913-4.173 4.406-10.202 6.029Q15.305 64 9.97 64m42.899 0q-3.48 0-5.334-.232a23 23 0 0 1-3.478-.696V48.465q4.87 0 5.797-2.551.928-2.783.928-4.406v-4.87h-2.32q-2.318 0-3.941-1.39-1.623-1.392-1.623-4.174V6.029q0-6.03 5.565-6.029h24.811q3.015 0 3.942 1.855 1.16 1.855 1.16 4.174v36.406q0 9.275-4.174 13.913Q70.029 60.754 64 62.377 58.202 64 52.87 64"
			style={{
				fill: '#f0ff00',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgQuote;
