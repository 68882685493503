import { CheckCircle, Warning } from '@bw/elements/icons';
import { FormMetaState } from '../formMetaState';
import styles from './formBottomToast.module.scss';

export const FormBottomToast = ({
  metaState,
  retrySubmit,
}) => {

  const statusClassName = metaState === FormMetaState.online ? styles.success 
    : [FormMetaState.retry, FormMetaState.fail, FormMetaState.offline].includes(metaState) ? styles.error 
    : '';

  const isOpenClassName = [FormMetaState.open, FormMetaState.submitting].includes(metaState) ? styles.close 
    : [FormMetaState.retry, FormMetaState.fail, FormMetaState.offline, FormMetaState.online].includes(metaState) ? styles.open
    : '';

  return (
    <div className={`${styles.formBottomToast} ${statusClassName} ${isOpenClassName}`}>
      {metaState === FormMetaState.retry ? (<>
        <Warning className={styles.icon} height={24} width={24} /> 
        <span>That didn&apos;t go as planned... <a onClick={retrySubmit}>Try again</a></span>
      </>) 
      : metaState === FormMetaState.fail ? (<>
        <Warning className={styles.icon} height={24} width={24} /> 
        <span>Looks like there&apos;s still an issue. Please contact us at <a href="tel:+1-888-254-5014">(888) 254-5014</a> for assistance.</span>
      </>) 
      : metaState === FormMetaState.offline ? (<>
        <Warning className={styles.icon} height={24} width={24} />
        <span>Bummer... Looks like your internet went out. Don&apos;t worry though, we&apos;ll wait.</span>
      </>) 
      : metaState === FormMetaState.online ? (<>
        <CheckCircle className={styles.icon} height={24} width={24} /> 
        <span>Nice! Your internet&apos;s back. Let&apos;s give this another try. <a onClick={retrySubmit}>Try again</a></span>
      </>) 
      : (<></>)
      }
    </div>
  );
}
