'use client';

declare var window: Window & { dataLayer: any[] };

export const gtmEvent = (eventName: string, params: Record<string, string>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...params,
    event: eventName
  });
};
