export const colorMapping = {
	black: ' #000',
	brightYellow: '#f0ff00',
	cream: '#ffffe6',
	deepBlue: '#003049',
	periwinkleGrey: ' #8291AC',
	white: '#fff',
	none: 'transparent',
};

export type ThemeColor = keyof typeof colorMapping;

export const paletteVariants = {
	primary: {
		backgroundColor: 'brightYellow',
		borderColor: 'brightYellow',
		textColor: 'deepBlue',
	},
	secondary: {
		backgroundColor: 'deepBlue',
		borderColor: 'deepBlue',
		textColor: 'white',
	},
	tertiary: {
		backgroundColor: 'white',
		borderColor: 'periwinkleGrey',
		textColor: 'deepBlue',
	}
} as const satisfies {
	[name: string]: {
		backgroundColor: ThemeColor
		borderColor: ThemeColor
		textColor: ThemeColor
	}
};

export type PaletteVariant = keyof typeof paletteVariants;