import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowDownLine = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 25"
		{...props}
	>
		<g clipPath="url(#arrow-down-line_svg__a)">
			<path
				fill="#8291AC"
				d="m13 16.672 5.364-5.364 1.414 1.414L12 20.5l-7.778-7.778 1.414-1.414L11 16.672V4.5h2z"
				style={{
					fill: '#8291ac',
					fillOpacity: 1,
				}}
			/>
		</g>
		<defs>
			<clipPath id="arrow-down-line_svg__a">
				<path
					fill="#fff"
					d="M0 0h24v24H0z"
					style={{
						fill: '#fff',
						fillOpacity: 1,
					}}
					transform="translate(0 .5)"
				/>
			</clipPath>
		</defs>
	</svg>
);
export default SvgArrowDownLine;
