import { RefObject, useEffect } from 'react';

export const usePostMessage = (
	targetRef: RefObject<HTMLIFrameElement | Window>,
	onMessage: (event: MessageEvent) => void
) =>
	useEffect(() => {
		if (!targetRef?.current) return;
		const targetWindow =
			targetRef.current instanceof HTMLIFrameElement
				? targetRef.current.contentWindow
				: targetRef.current;

		const parseEvents = (event: MessageEvent) => {
			if (event.source !== targetWindow) return;
			onMessage(event);
		};

		window.addEventListener('message', parseEvents);

		return () => {
			window.removeEventListener('message', parseEvents);
		};
	}, [window]);
