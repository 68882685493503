'use client';
import { createContext, useContext, useState } from 'react';

// TODO(khaney): separate or eliminate these
const AppContext = createContext<{
	showFormStepper: boolean,
	setShowFormStepper: (show: boolean) => void,
	announcementBannerHeight: number,
	setAnnouncementBannerHeight: (height: number) => void,
	podcastPopupDisplay: boolean,
	setPodcastPopupDisplay: (show: boolean) => void,
}>({} as any); // {} as any is a hack to avoid having to define a default value for the context

export const StepperProvider = ({ formStepper, children, svgs }: any) => {
	const [showFormStepper, setShowFormStepper] = useState(false);
	const [announcementBannerHeight, setAnnouncementBannerHeight] =
		useState(0);
	const [podcastPopupDisplay, setPodcastPopupDisplay] = useState(false);

	return (
		<AppContext.Provider
			value={{
				showFormStepper,
				setShowFormStepper,
				announcementBannerHeight,
				setAnnouncementBannerHeight,
				podcastPopupDisplay,
				setPodcastPopupDisplay,
			}}
		>
			{showFormStepper && formStepper}
			{children}
		</AppContext.Provider>
	);
};

export function useAppContext() {
	return useContext(AppContext);
}
