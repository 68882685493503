import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInstagramFill = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M12 2c-2.716 0-3.056.011-4.123.06-1.064.049-1.792.218-2.428.465A4.9 4.9 0 0 0 3.678 3.68a4.9 4.9 0 0 0-1.153 1.77c-.246.636-.416 1.364-.465 2.429C2.012 8.944 2 9.283 2 12s.011 3.056.06 4.123c.049 1.064.218 1.792.465 2.428.251.666.644 1.271 1.154 1.771.5.509 1.103.902 1.77 1.153.636.247 1.364.416 2.428.465 1.067.049 1.407.06 4.123.06s3.056-.011 4.123-.06c1.064-.049 1.792-.218 2.428-.465a4.9 4.9 0 0 0 1.771-1.154c.509-.5.902-1.103 1.153-1.77.247-.636.416-1.364.465-2.428.049-1.067.06-1.407.06-4.123s-.011-3.056-.06-4.123c-.049-1.064-.218-1.792-.465-2.428a4.9 4.9 0 0 0-1.154-1.771 4.9 4.9 0 0 0-1.77-1.153c-.636-.246-1.364-.416-2.429-.465C15.056 2.012 14.717 2 12 2m0 1.802c2.67 0 2.987.01 4.041.058.975.045 1.504.207 1.857.345.467.18.8.398 1.15.747.35.35.567.683.747 1.15.138.353.3.882.345 1.857.048 1.054.058 1.371.058 4.041s-.01 2.987-.058 4.041c-.045.975-.207 1.504-.345 1.857-.16.434-.415.827-.747 1.15a3.1 3.1 0 0 1-1.15.747c-.353.138-.882.3-1.857.345-1.054.048-1.37.058-4.041.058-2.67 0-2.987-.01-4.041-.058-.975-.045-1.504-.207-1.857-.345a3.1 3.1 0 0 1-1.15-.747 3.1 3.1 0 0 1-.747-1.15c-.138-.353-.3-.882-.345-1.857-.048-1.054-.058-1.371-.058-4.041s.01-2.987.058-4.041c.045-.975.207-1.504.345-1.857.18-.467.398-.8.747-1.15a3.1 3.1 0 0 1 1.15-.747c.353-.138.882-.3 1.857-.345C9.013 3.812 9.33 3.802 12 3.802"
			clipRule="evenodd"
			style={{
				fill: '#fff',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M12 15.337a3.337 3.337 0 1 1 0-6.674 3.337 3.337 0 0 1 0 6.674m0-8.477a5.14 5.14 0 1 0 0 10.28 5.14 5.14 0 0 0 0-10.28m6.635-.093a1.215 1.215 0 1 1-2.43 0 1.215 1.215 0 0 1 2.43 0"
			clipRule="evenodd"
			style={{
				fill: '#fff',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInstagramFill;
