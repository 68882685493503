import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsHome = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M82.68 82.49h-7.64V44.37l7.06 7.06c.24.24.56.37.88.37s.64-.12.88-.37c.49-.49.49-1.28 0-1.77l-9.19-9.19-24.1-24.1c-.23-.23-.55-.37-.88-.37s-.65.13-.88.37L37.33 27.85v-5.66c0-2.49-2.03-4.52-4.52-4.52h-3.96c-2.49 0-4.52 2.03-4.52 4.52v18.66l-8.83 8.81a1.25 1.25 0 0 0 0 1.77c.49.49 1.28.49 1.77 0l7.06-7.05v38.11h-8.08a1.25 1.25 0 0 0 0 2.5h66.43a1.25 1.25 0 0 0 0-2.5M26.83 22.18c0-1.11.9-2.02 2.02-2.02h3.96c1.11 0 2.02.9 2.02 2.02v8.08s.01.04.01.07l-8.01 8.01zm25.21 60.3V58.32c0-.31.25-.57.57-.57h8.99c.31 0 .57.25.57.57v24.16zm12.63 0V58.32c0-1.69-1.38-3.07-3.07-3.07h-8.99c-1.69 0-3.07 1.38-3.07 3.07v24.16H26.83v-40.6l22.85-22.86 22.86 22.86v40.61h-7.88z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsHome;
