import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLinkedinFill = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		{...props}
	>
		<path
			fill="#fff"
			d="M22 3.47v17.06A1.47 1.47 0 0 1 20.53 22H3.47A1.47 1.47 0 0 1 2 20.53V3.47A1.47 1.47 0 0 1 3.47 2h17.06A1.47 1.47 0 0 1 22 3.47M7.882 9.648h-2.94v9.412h2.94zm.265-3.235a1.694 1.694 0 0 0-1.682-1.706h-.053a1.706 1.706 0 0 0 0 3.412 1.694 1.694 0 0 0 1.735-1.653zm10.912 6.93c0-2.83-1.8-3.93-3.588-3.93a3.35 3.35 0 0 0-2.977 1.517h-.082V9.647H9.647v9.412h2.941v-5.006a1.953 1.953 0 0 1 1.765-2.106h.112c.935 0 1.63.588 1.63 2.07v5.042h2.94z"
			style={{
				fill: '#fff',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgLinkedinFill;
