'use client';
import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import { useDebouncedCallback } from 'use-debounce';

export function Searchbar() {
	const searchParams = useSearchParams();
	const pathname = usePathname();
	const { replace } = useRouter();

	const onSearchTextChange = useDebouncedCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const searchTerm = e.target.value;
			const params = new URLSearchParams(searchParams);
			if (searchTerm) {
				params.set('query', searchTerm);
			} else {
				params.delete('query');
			}
			params.delete('page');
			replace(`${pathname}?${params.toString()}`);
		},
		500
	);

	return (
		<>
			<input
				aria-label="search for articles"
				id="search"
				placeholder="Search"
				onChange={onSearchTextChange}
				defaultValue={searchParams.get('query')?.toString()}
				style={{
					border: 'solid 2px',
					borderRadius: '5px',
					textAlign: 'center',
				}}
			/>
		</>
	);
}
