// TODO(khaney): will these ctfasset urls break if we delete/rename the environment?
// TOOD(khaney): familyinlogo and familywithlogo need to be replaced
export const additionalRep = 'https://images.ctfassets.net/bggn69gkp7hl/1GbevQyd0VAUgQVsd0xeWR/74838e30afe27b28b3a36d03e7aa58eb/team3.png';
export const agencyHome = 'https://images.ctfassets.net/bggn69gkp7hl/HTewTijErIK0gj9CmfcwY/6899eeb4bf4a19eee8730904b923f30f/agencyhome1.jpg';
export const agencyhome2 = 'https://images.ctfassets.net/bggn69gkp7hl/2HnB4pyZeCUe507WUVLZES/1028e4357c6139088a217a307f02991d/agencyhome2.jpg';
export const agencyhome3 = 'https://images.ctfassets.net/bggn69gkp7hl/1r9gq6PymykufSjmR74c2R/3ee47177c9cc1e7eaacaa9393aabfc19/agencyhome3.jpg';
export const agencyHomehero = 'https://images.ctfassets.net/bggn69gkp7hl/FrLdvdOLT7nA0E2Kd7BD3/330f0a534153dbd8633c350d62d8f75b/agencyHomehero.jpg';
export const agencyhomethree = 'https://images.ctfassets.net/bggn69gkp7hl/5TMWmzOyTFawIrOsOKo4mP/57171fa6478351b771779ae5c6732477/agencyhomethree.png';
export const Bike_repair = 'https://images.ctfassets.net/bggn69gkp7hl/3rBk0D3Jh7ZBDengU0DTvv/4b2e9e410eeaf783b3a26af40228fa44/Bike_repair.jpg';
export const BikeInBeach = 'https://images.ctfassets.net/bggn69gkp7hl/7fynYMRhXWXWclu7UXJIh4/be5875e5eb430f099365549287cee2cc/BikeInBeach.jpg';
export const Bike_in_road = 'https://images.ctfassets.net/bggn69gkp7hl/5s3UzkUsyxOk37ufQ0vOaH/c47b357cbaab84502984926877e7dbe6/Bike_in_road.jpg';
export const Boat_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/4P6a89lzhAiRFQ4se0z3q5/a17550ac880bf32445ea4d9e69d6d852/Boat_inLogo.jpg';
export const Boat_side_view = 'https://images.ctfassets.net/bggn69gkp7hl/3WFCWvYlpTadUCNs7KRBLn/e6ddb718b7976faab8f7327667ead990/Boat_side_view.jpg';
export const Boat_sun_set = 'https://images.ctfassets.net/bggn69gkp7hl/1JRvXmj066nrkyrvc9kpBU/ace0227f994e2fe9ee6432bffc20767b/Boat_sun_set.jpg';
export const BoatFrontView = 'https://images.ctfassets.net/bggn69gkp7hl/6176yIJDyU8cIpYgpeXGmV/c74245872e771ab111399a7e15fd494b/Boat_front_view.jpg';
export const Brightway_Logo = 'https://images.ctfassets.net/bggn69gkp7hl/34Why0fGxiaa22HjKavUeA/7be80da2cd3ba76b5b4cbe5b3c8da851/Brightway_Logotype.svg';
export const brightway_logo_mark = 'https://images.ctfassets.net/bggn69gkp7hl/p5cDzGkFoPqGKJIDaeYs5/b711caee439a05f2e703829618e948a1/brightway_logo_mark.png';
export const brightwayFooter = 'https://images.ctfassets.net/bggn69gkp7hl/6ikjQLyrfmICtBH34Iv7Qp/19cf7babfaabc2695461554b77b996db/brightway-footer.svg';
export const Caravan = 'https://images.ctfassets.net/bggn69gkp7hl/SWoGejnHwaxX7NApyVcrt/28370a71afd722127b09e338d87aa603/Caravan.jpg';
export const CarCamper_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/1kmNO6ySB4Q797EVxlAWyz/c76363f31897dd5a42351c749902d68d/CarCamper_inLogo.jpg';
export const CatInChair = 'https://images.ctfassets.net/bggn69gkp7hl/1VhqbIwesYFh6I3OPxMzPP/a12acee2715d340d869928ce8d44b3ca/Cat_in_chair.jpg';
export const ChildPlayingWithDog = 'https://images.ctfassets.net/bggn69gkp7hl/4C7MhjLPGNxNFt5IXOQKNm/7e34271128e330ac767f7ad2499044e2/kateryna-hliznitsova-gTMiaAFR268-unsplash.jpg';
export const childrenPlayingImg = 'https://images.ctfassets.net/bggn69gkp7hl/7egbMxk7WJQJyKOsRgS1Z0/735171cc5dcd0d4a8a2482d4c7e0856d/children_playing.jpg';
export const ChildRunning = 'https://images.ctfassets.net/bggn69gkp7hl/2QvC0S9FufpCqwanfrUGuL/6de69febdc6064317f2262d7448f16f3/Child_running.jpg';
export const condoHero = 'https://images.ctfassets.net/bggn69gkp7hl/5NdPVXPjjdFcFLH7VJp4IW/eefac4abf0af2ce060df33e1a9ce9584/benjamin-massello-y-hnPtZo0kk-unsplash.jpg';
export const contactusformImage = 'https://images.ctfassets.net/bggn69gkp7hl/2OSYVEQWWsLW66BALVJhKw/529ec5f51244254cc306240f4db80120/contactusformImage.jpg';
export const CoupeandRealtor = 'https://images.ctfassets.net/bggn69gkp7hl/6wDLwQrx3Cmjf8LeeXtOkt/209dddd7e6b6c64fc06070f54f9b4887/CoupeandRealtor.jpg';
export const Couple_in_Bike = 'https://images.ctfassets.net/bggn69gkp7hl/C6UwYr1efQEW8MZhFE6P9/6391333bece55aafbc1467332684c194/Couple_in_Bike.jpg';
export const CoupleMoving_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/1TMlGcBpFX6l8GjMCBquGk/3a968abeb596da16ec60563ff647efb6/CoupleMoving_inLogo.jpg';
export const CoupleMovingIn__3_ = 'https://images.ctfassets.net/bggn69gkp7hl/6pJ1fX9FSGJ0uUokzM5vvf/215e4d2332ee65f820c4d5df06942f4c/CoupleMovingIn__3_.jpg';
export const Cruise = 'https://images.ctfassets.net/bggn69gkp7hl/4Z1TwAnOZjgK0A02AxPVCV/c91f804b2148080cac765245720c0682/Cruise.jpg';
export const CycleOutside = 'https://images.ctfassets.net/bggn69gkp7hl/43VRmGJBjPp49f7MFFaP0w/f025243320f1107cd19a388a1610670e/artem-gavrysh-F6-U5fGAOik-unsplash.jpg';
export const Doctor_checking_dog = 'https://images.ctfassets.net/bggn69gkp7hl/1jC1WZL0zwKh293yeD8HXr/05f29b3f0fea98e0b5ed1499b8592ea4/Doctor_checking_dog.jpg';
export const Dog_In_Window_Logo = 'https://images.ctfassets.net/bggn69gkp7hl/5VRSK0xESsnWX5EGnt5ABi/6a56ea0c8426159108e6e65bdad4f7a6/Dog_In-Window_Logo.jpg';
export const DogandHouse_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/6AOAmqc6gYOrYJkcs5u9Wk/14f4a14982d82627594318eaf17459ac/DogandHouse_inLogo.jpg';
export const DogandHouseInLogo = 'https://images.ctfassets.net/bggn69gkp7hl/2rx5mrzV9P1ABOioAlny4q/5a045fc5311c2465991d91ee335ecb99/DogandHouseInLogo.jpg';
export const Family_Cooking = 'https://images.ctfassets.net/bggn69gkp7hl/6IBl7iXN8Z5DRkECHFbdjv/8b6e88039d877f5c05c4a464af7646c8/Family_Cooking.jpg';
export const FamilyBreakfast_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/uS8m9UGc7TjPhSMt82NwK/f7e9d2fa53a624c16798dd314e4911ab/FamilyBreakfast_inLogo.jpg';
export const FamilybyWater_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/4izL7QFuYLxctiMQAvfHUG/11a41d093226eab381a50d79f3b33a73/FamilybyWater_inLogo.jpg';
export const FamilyDancing_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/cAbDH25qAIi6zROb298eV/fad9708e5c26cb07876cef27911ad422/FamilyDancing_inLogo.jpg';
export const familyImg = 'https://images.ctfassets.net/bggn69gkp7hl/3CPsdtp5lXyQ3ZVxjBx0K/85e8620251df96c10095f48af7ecd387/Family.jpg';
export const FamilyOnPorch_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/8t7m6NlYA56KsHQOEn1vs/316029649a0ce51bcab667db027d6521/FamilyOnPorch_inLogo.jpg';
export const FamilyOnPorch_with_Logo = 'https://images.ctfassets.net/bggn69gkp7hl/2TR3WmcTG8voH3vl6RNWqw/344054ed89dc95dbbdb5e69f2ea1a036/FamilyOnPorch_with_Logo.jpg';
export const familyWithDogImg = 'https://images.ctfassets.net/bggn69gkp7hl/5NEjZi7r6Nxa0OKxZZ7v76/d885e59d21c9cc37356d8da2dab5ad30/family_with_dog.jpg';
export const Form_image = 'https://images.ctfassets.net/bggn69gkp7hl/6p4UMtMXz9meYQXLnNrToI/eae05deb734b46fa7c4a544620c24b2e/Form_image.jpg';
export const FriendsCouch_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/5u3YKVDMU25P5T8imlZVz5/920638f95ecd28380eb674b6ccc83202/FriendsCouch_inLogo.jpg';
export const grandpaWithCarImg = 'https://images.ctfassets.net/bggn69gkp7hl/2lR66I85s69e2mEEiwYFQ9/c366e2a4263fef7921db7aec7a7a28df/grandpa_with_car.jpg';
export const Guitar = 'https://images.ctfassets.net/bggn69gkp7hl/4x1ReajYg3LxmUFFpc5mnp/f1968b5deb95c1149165a716a4a70511/Guitar.jpg';
export const heroImg = 'https://images.ctfassets.net/bggn69gkp7hl/38KWpa8UF7fTRg5gi2dDr5/5dba534a147f1dafea724c1d8d497c3a/Hero.jpg';
export const homeInsurance = 'https://images.ctfassets.net/bggn69gkp7hl/WgNQkO33AZ4cXPAt38ppv/59b7c111ebf01508bed6117c145e78e3/HomeInsur_getty-images-VQEjZoqPbr8-unsplash.jpg';
export const horner = 'https://images.ctfassets.net/bggn69gkp7hl/16e9Xk7fOYEGldBHOA1mo7/53de7d75eea54e91a2a7c95dda4071e2/horner.jpg';
export const Hotel = 'https://images.ctfassets.net/bggn69gkp7hl/WcN7fqZh93blz2g5Gymv7/160bc0d668fab446920bec6dddc948cb/keem-ibarra-I2Dz5Hljm9g-unsplash.jpg';
export const insideHome = 'https://images.ctfassets.net/bggn69gkp7hl/2yeRuUMWS4Hpy4UlODvowF/fecf01241bfa826ea8bb550979f42943/chastity-cortijo-98WE9hWWjiQ-unsplash.jpg';
export const Jetski = 'https://images.ctfassets.net/bggn69gkp7hl/1SPghZrEuVFITrOvj45wlJ/02c86e1f85bc32a30c776b780c87d0f0/Jetski.jpg';
export const Laptop_working = 'https://images.ctfassets.net/bggn69gkp7hl/6N4nG6ttNxZ8zNO83xgTmt/a29d5fd3259835eece6fb5203c7d8982/Laptop_working.jpg';
export const Letter_writing = 'https://images.ctfassets.net/bggn69gkp7hl/37Gc8QoxWFFMpdPAtj7Dx8/ff6a1486eb2469b4b6f29eda2f1ef51c/Letter_writing.jpg';
export const logoAnchor = 'https://images.ctfassets.net/bggn69gkp7hl/4A0GlNhATgS0N4mww128TE/e346abaa1b985ec37d24044d9e34b17b/Logo_Anchor.png';
export const man = 'https://images.ctfassets.net/bggn69gkp7hl/gaH7vuSU7eE5spzK7j3cV/fbf3b27b0dfc13630e73382908759c96/man.png';
export const ManAndBabyStudio_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/42yeAhA4g6QSIOV3sRht49/19daf0232efb394d63716d5317f22e6f/ManAndBabyStudio_inLogo.jpg';
export const ManOutside_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/4rWXfeTRJuX5I312cnPuBX/54f5623c1e409662ad086eb8c8e87f3f/ManOutside_inLogo.jpg';
export const map_pin = 'https://images.ctfassets.net/bggn69gkp7hl/7io8QBatzs2gk0mHN4icXP/cdfd43fa029e8a6d3141d684b1368320/map_pin.svg';
export const map_pin_active = 'https://images.ctfassets.net/bggn69gkp7hl/20DS173u8bHoCk3ThjDe4V/982472b50925ac8cb106f082211c43e3/Frame_124.svg';
export const NewCarOwner_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/5ofjfZi3tnTuQ1FiouVJ3h/537bd2c430aedb148d1234155e72ade9/NewCarOwner_inLogo.jpg';
export const Ornaments = 'https://images.ctfassets.net/bggn69gkp7hl/7k8IU61N7YfbC58SMACR3U/bc8dd60686934d0f2c61293167e4ace3/Ornaments.jpg';
export const PeopleBoatJumping = 'https://images.ctfassets.net/bggn69gkp7hl/3iKYSkTGomErbGvc0cNT7s/3767bb6692be21ae5d200038b231147b/PeopleBoatJumping.jpg';
export const PeopleInOffice_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/7JdRbIdButyce9ocybGVs6/b8adc6c9a70181b588c1d0cfccb16ee5/PeopleInOffice_inLogo.jpg';
export const plumping = 'https://images.ctfassets.net/bggn69gkp7hl/5DYWzZJ7SCMZErhfLiRFBm/587e18550340f252061def744f083d2e/plumping.jpg';
export const podcastBanner = 'https://images.ctfassets.net/bggn69gkp7hl/3hvSRwwxEcTgzPUJJhl0OS/79f02dbb2bb3aee8adfdf88860687d20/podcast-banner.jpg';
export const Property_1_SmallModernHouse_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/6OzpLM63Jn8JPIpdImciCq/3ffc7483f2afb0a9649237afa4b98eb9/Property_1_SmallModernHouse_inLogo.jpg';
export const Property_1_Studio_Woman_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/1UTiJmyeiwkKRnMT3BhFo8/85c0488452eef765b8b5ca8d334a29b0/Property_1_Studio_Woman_inLogo.jpg';
export const Relax_in_woods = 'https://images.ctfassets.net/bggn69gkp7hl/2ZapCrBdmB80eHaTDEER0y/1dee81802b6e73af514f42a130c8c471/Relax_in_woods.jpg';
export const rickFoxImg = 'https://images.ctfassets.net/bggn69gkp7hl/1rjameKuqMhoTXSSC2QSPU/9238b786169ad11ce436dc042c4ac120/rick.png';
export const RV_camp = 'https://images.ctfassets.net/bggn69gkp7hl/4hA6cBCzUSF8LWGCO9oPja/4e02a502d96b6d24d8a593e90dbf84a0/RV_camp.jpg';
export const RVinBeach = 'https://images.ctfassets.net/bggn69gkp7hl/3q4z3jQ3qx1cQcgkO3dmRW/fb174700510935839410079ffba4bdbb/RV_in_beach.jpg';
export const SmallModernHouse = 'https://images.ctfassets.net/bggn69gkp7hl/4ouhDouUAsTXjCDYK6ezso/9a5c8cc5af6aa5f6c6f04b33095e1177/SmallModernHouse.jpg';
export const TakingBag = 'https://images.ctfassets.net/bggn69gkp7hl/19VftiIyIv0v00lX4Arbpm/a9a440e14c0ead817d7794e96bdf270b/TakingBag.jpg';
export const WomanOffice_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/0DhbMqvUKx3vUQvfKRtyN/dbac8a70fa0dcbd200b2946b645e9241/WomanOffice_inLogo.jpg';
export const WomenStore_inLogo = 'https://images.ctfassets.net/bggn69gkp7hl/1e8GPD7Yk64hSpJFBMLGlh/42891a69e84cccb884002fed6885ef49/WomenStore_inLogo.jpg';
export const Workers = 'https://images.ctfassets.net/bggn69gkp7hl/b3voIZ78XzAxw0DsIsv8q/92b08d1958922fd02ed64ead812c2744/getty-images-HfmK_xV5O7I-unsplash.jpg';
