import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInsuranceIconsCondo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 100 100"
		{...props}
	>
		<path
			fill="#003049"
			d="M83.68 82.07h-7.64V17.25c0-.69-.56-1.25-1.25-1.25H26.58c-.69 0-1.25.56-1.25 1.25v64.82h-8.08a1.25 1.25 0 0 0 0 2.5h66.43a1.25 1.25 0 0 0 0-2.5M73.54 18.5v3.48H27.83V18.5zM56.29 82.07V64.6c0-.31.25-.57.57-.57h6.95c.31 0 .57.25.57.57v17.47h-8.09m10.58 0V64.6c0-1.69-1.38-3.07-3.07-3.07h-6.95c-1.69 0-3.07 1.38-3.07 3.07v17.47H27.83V24.48h45.71v57.6h-6.67z"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
		<path
			fill="#003049"
			d="M44.91 65.55h-7.88a1.25 1.25 0 0 0 0 2.5h7.88a1.25 1.25 0 0 0 0-2.5M44.91 32.29h-7.88a1.25 1.25 0 0 0 0 2.5h7.88a1.25 1.25 0 0 0 0-2.5M44.91 48.92h-7.88a1.25 1.25 0 0 0 0 2.5h7.88a1.25 1.25 0 0 0 0-2.5M64.27 32.29h-7.88a1.25 1.25 0 0 0 0 2.5h7.88a1.25 1.25 0 0 0 0-2.5M64.27 48.92h-7.88a1.25 1.25 0 0 0 0 2.5h7.88a1.25 1.25 0 0 0 0-2.5"
			style={{
				fill: '#003049',
				fillOpacity: 1,
			}}
		/>
	</svg>
);
export default SvgInsuranceIconsCondo;
